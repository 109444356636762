<template>
	<div style="position: relative; overflow: hidden;">
		<template v-if="!mobile">
			<div class="head_tracks" v-if="detailsTrack">
				<div class="title_" v-if="SHOW_COLUMNS_TRACK.includes('title')">Title</div>
				<div class="artist_" v-if="SHOW_COLUMNS_TRACK.includes('artist')">Artist</div>
<!--				<div class="label_">Label</div>-->
				<div class="released_" v-if="SHOW_COLUMNS_TRACK.includes('released')">Released</div>
				<div class="bpm_" v-if="SHOW_COLUMNS_TRACK.includes('bpm')">BPM
					<div class="sort">
						<span class="material-icons" @click="orderBy('bpm', 'asc')" :class="{'active': currentOrderBy.name === 'bpm_asc'}">arrow_drop_up</span>
						<span class="material-icons" @click="orderBy('bpm', 'desc')" :class="{'active': currentOrderBy.name === 'bpm_desc'}">arrow_drop_down</span>
					</div>
				</div>
				<div class="key_" v-if="SHOW_COLUMNS_TRACK.includes('key')">KEY
					<div class="sort">
						<span class="material-icons" @click="orderBy('key', 'asc')" :class="{'active': currentOrderBy.name === 'key_asc'}">arrow_drop_up</span>
						<span class="material-icons" @click="orderBy('key', 'desc')" :class="{'active': currentOrderBy.name === 'key_desc'}">arrow_drop_down</span>
					</div>
				</div>

				<div class="actions_">
					<v-menu
						transition="slide-y-transition"
						:close-on-content-click="false"
						:nudge-width="120"
						content-class="menu-pointer charcoal"
						offset-y
						nudge-left="60"
						nudge-bottom="25"
						origin="top center"
					>
						<template v-slot:activator="{ on, attrs }">
							<button v-bind="attrs" v-on="on">
								<span class="material-icons">arrow_drop_down</span>
							</button>
						</template>
						<v-list>
							<v-list-item>
								<div class="material_checkbox">
									<input type="checkbox" id="title" value="title" v-model="columns"
									       @change="checkColumn($event)">
									<label for="title">Title</label>
								</div>
							</v-list-item>
							<v-list-item>
								<div class="material_checkbox">
									<input type="checkbox" id="artist" value="artist" v-model="columns"
									       @change="checkColumn($event)">
									<label for="artist">Artist</label>
								</div>
							</v-list-item>
							<v-list-item>
								<div class="material_checkbox">
									<input type="checkbox" id="released" value="released" v-model="columns"
									       @change="checkColumn($event)">
									<label for="released">Released</label>
								</div>
							</v-list-item>

							<v-list-item>
								<div class="material_checkbox">
									<input type="checkbox" id="bpm" value="bpm" v-model="columns"
									       @change="checkColumn($event)">
									<label for="bpm">BPM</label>
								</div>
							</v-list-item>
							<v-list-item>
								<div class="material_checkbox">
									<input type="checkbox" id="key" value="key" v-model="columns"
									       @change="checkColumn($event)">
									<label for="key">Key</label>
								</div>
							</v-list-item>

						</v-list>
					</v-menu>
				</div>
			</div>
			<div class="list_album_head_tracks" v-if="!detailsTrack">
				<div class="__title">Title</div>
				<div class="__artist">Artist</div>
				<div class="__duration">Duration</div>
				<div class="__bpm">BPM</div>
				<div class="__key">Key</div>
				<div class="__size">Size</div>
			</div>
			<div class="album-playlist">

				<template v-if="albumPlayList.length">
					<!--                    <transition-group name="fade" tag="div">-->
					<div v-for="(track, index) of albumPlayList" :key="track.id"
					     v-if="track.song.isFavoriteHidden === false"
					     class="item_row_track"
					     :class="[{ selected: currentTrack !== null && currentTrack.id && track.song.id === currentTrack.id && playing}]">
						<div class="track">
							<div class="play_pause">
								<template v-if="track.song.link">
<!--									<div class="track_deleted_block" v-if="track.song.deletedAt && (album.isFavorite || album.isPurchased)"></div>-->
									<div class="track_deleted_block" v-if="track.song.deletedAt"></div>
									<template v-else>
										<button v-if="currentTrack !== null && currentTrack.id === track.song.id && playing" class="pause" @click="pause"></button>
										<button v-else class="play" @click="playTrack(track, index, album)"></button>

										<v-progress-circular
											v-if="currentOrderBy.active === false && currentTrack !== null && currentTrack.id === track.song.id"
											:rotate="-90"
											:size="!detailsTrack ? 30: 35"
											:width="2"
											:value="Math.round(trackProgress)"
											color="#20C4F5">
										</v-progress-circular>
									</template>

								</template>

								<template v-else>
									<v-tooltip top close-delay="0" transition="false">
										<template v-slot:activator="{ on, attrs }">
											<div class="track_error" v-bind="attrs" v-on="on"></div>
										</template>
										<div>Unable to play at this time</div>
									</v-tooltip>
								</template>
							</div>

<!--							<div class="track_deleted" v-if="track.song.deletedAt && (album.isFavorite || album.isPurchased)" @click="deleteTrack(track.song)"></div>-->
							<div class="track_deleted" v-if="track.song.deletedAt" @click="deleteTrack(track.song)"></div>
							<template v-else>
								<div class="is_purchased" v-if="track.song.isPurchased === true"></div>
								<template v-else>
									<button class="like" v-if="track.song.isFavorite === false" @click="addTrackToFavorites(track.song, album)"></button>
									<button class="like active" v-if="track.song.isFavorite === true" @click="removeTrackFromFavorites(track.song, album)"></button>
								</template>
							</template>


							<!-- item album list -->
							<template v-if="!detailsTrack">
								<span class="track_name">{{ track.song.title }}</span>
								<template v-if="track.song.artist">
									<div class="artist_name" @click="redirectToArtistPage(track.song.artist.id, track.song.artist.identifier)">
										{{ track.song.artist.name }}
									</div>
								</template>
								<template v-else>
									<div class="artist_name">-</div>
								</template>
								<div class="duration">
									<template v-if="track.song.duration">{{ track.song.duration }}</template>
									<template v-else>-</template>
								</div>
								<div class="bpm">
									<template v-if="track.song.bpm && track.song.bpm !== 0">{{ track.song.bpm }}</template>
									<template v-else>-</template>
								</div>
								<div class="key">
									<template v-if="track.song.key">{{ getSongKey(track.song) }}</template>
									<template v-else>-</template>
								</div>
								<div class="size">
									<template v-if="track.song.size">{{ track.song.size }}</template>
									<template v-else>-</template>
								</div>
							</template>

							<!-- albumDetails page -->
							<template v-if="detailsTrack">
								<span class="track_name" v-if="SHOW_COLUMNS_TRACK.includes('title')">{{ track.song.title }}</span>

								<div v-if="track.song.artist" class="artist_name">
									<div v-if="SHOW_COLUMNS_TRACK.includes('artist')" @click="redirectToArtistPage(track.song.artist.id, track.song.artist.identifier)">
										{{ track.song.artist.name }}
									</div>

								</div>
								<template v-else>
									<div class="artist_name">-</div>
								</template>

								<div class="released" v-if="SHOW_COLUMNS_TRACK.includes('released')">
									{{ track.song.publishedAt | moment("MMM, YYYY") }}
								</div>
<!--								<div class="label" v-if="track.song.recordLabel">-->
<!--									{{track.song.recordLabel.title}}-->
<!--								</div>-->
<!--								<div v-else class="label">-</div>-->


								<div class="bpm" v-if="SHOW_COLUMNS_TRACK.includes('bpm')">
									{{ track.song.bpm }}
								</div>
								<div class="key" v-if="SHOW_COLUMNS_TRACK.includes('key')">
                                    {{ getSongKey(track.song) }}
                                </div>
							</template>
						</div>

						<div class="action_track">
<!--							<div class="track_deleted_block" v-if="track.song.deletedAt && (album.isFavorite || album.isPurchased)"></div>-->
							<div class="track_deleted_block" v-if="track.song.deletedAt"></div>
							<template v-else>
								<template v-if="album.isPurchased !== true">
									<template v-if="track.song.isPurchased !== true">
										<template v-if="album.inCart === true">
											<button type="button" class="track_paid track_in_cart" @click="openCart" id="open-header-shopping-cart">
												<span class="in_cart">In Cart</span>
											</button>
										</template>
										<template v-else>
											<button type="button" class="track_paid" v-if="track.song.price > 0 && track.song.inCart === false">
												<span class="price">${{ track.song.price }}</span>
												<span class="add_to_cart" @click="setYouAlbumPrice(track.song, 'buy')">Add to Cart</span>
											</button>

											<button type="button" class="track_paid track_in_cart" id="open-header-shopping-cart" v-if="track.song.inCart === true"
											        @click="openCart">
												<span class="in_cart">In Cart</span>
											</button>

											<button type="button" class="track_syp" v-if="track.song.price === 0 && track.song.inCart === false"
											        @click="setYouAlbumPrice(track.song, 'set')">
												<span class="default_name">SYP</span>
												<span class="full_name">Set your price</span>
											</button>
										</template>
									</template>
								</template>

								<template v-if="track.song.isPurchased === true || album.isPurchased === true || album.isFree === true">
									<a class="download_track" @click.prevent="checkPossibilityDownloadTrack(track.song)">
										<span>Download</span>
									</a>
								</template>
								<template v-else-if="album.inCart !== true && album.isFree !== true && (!track.song.price && track.song.price !== 0)">
									<div class="download_track disabled"></div>
								</template>
							</template>


							<v-menu
								transition="slide-y-transition"
								:close-on-content-click="false"
								:nudge-width="120"
								:v-model="viewMenuTrack"
								offset-y
								nudge-left="60"
								nudge-bottom="15"
								origin="top center"
								content-class="menu-pointer charcoal">

								<template v-slot:activator="{ on, attrs }">
									<button type="button" class="more" v-bind="attrs" v-on="on"></button>
								</template>
								<v-list class="playlists">
									<v-list-item>
										<!-- <button v-if="!trackInPlaylist[track.id]" type="button" @click="addTrackToPlaylist({album, track})">Add to queue</button>-->
										<button type="button">Add to queue</button>
										<!-- <button v-else class="no_active">Added to queue</button>-->
									</v-list-item>
									<v-list-item>
										<button>Play next</button>
									</v-list-item>
									<v-list-item>
										<button type="button" @click="addTrackToUserPlaylist">Add to Playlist</button>
										<!--                                            <button type="button" @click="viewUserPlaylistsDialog = true">Add to Playlist</button>-->
										<!--                                            <button type="button" @click="viewMenuPlayList = true; viewMenuTrack = false"-->
										<!--                                                    id="btn_open_playlist_menu">Add to Playlist-->
										<!--                                            </button>-->
									</v-list-item>
									<v-list-item>
										<button>Report</button>
									</v-list-item>
								</v-list>

								<!--								<userPlayLists v-model="viewUserPlaylistsDialog"></userPlayLists>-->
								<!--                                    <v-card v-else class="add_to_playlist" v-click-outside="clickOutsidePlaylistMenu">-->
								<!--                                        &lt;!&ndash; v-click-outside="clickOutsidePlaylistMenu" &ndash;&gt;-->
								<!--                                        <div class="head_">Add to Playlist</div>-->

								<!--                                        <div class="wrapper_playlists">-->
								<!--                                            <template v-if="!playlists.length">-->
								<!--                                                <div class="none_playlists">You have no playlists</div>-->
								<!--                                            </template>-->
								<!--                                            <div class="playlists_box">-->
								<!--                                                <div class="material_checkbox" v-for="(playlist, index) in playlists"-->
								<!--                                                     :key="playlist.id">-->
								<!--                                                    <input type="checkbox" :id="index" :value="playlist" v-model="selectedPlaylist"-->
								<!--                                                           @change="checkPlaylists(track.song.id, playlist.id)">-->
								<!--                                                    <label :for="index">{{ playlist.title }}</label>-->
								<!--                                                </div>-->
								<!--                                            </div>-->
								<!--                                        </div>-->

								<!--                                        <div class="action_box">-->
								<!--                                            <div class="create_playlist">-->
								<!--                                                <input type="text" placeholder="+ New Playlist" v-model="newPlayList">-->
								<!--                                            </div>-->
								<!--                                            <button v-if="newPlayList || selectedPlaylist.length" type="button"-->
								<!--                                                    @click="savePlayList(track.song.id)">Save-->
								<!--                                            </button>-->
								<!--                                            <button v-if="!newPlayList && !selectedPlaylist.length" type="button"-->
								<!--                                                    @click="closeTrackMenu">Done-->
								<!--                                            </button>-->
								<!--                                        </div>-->
								<!--                                    </v-card>-->

							</v-menu>
						</div>

					</div>
					<!--                    </transition-group>-->
				</template>
				<button v-if="btnShowAllTracks === true" v-ripple type="button" class="toogle_view_tracks"
				        :class="{'secondary_btn medium': detailsTrack}"
				        @click="toggleViewTracks">
					{{ (PLAYED_ONLY_FAV_TRACKS === true) ? "View Full Album" : "Hide Tracks Not in Library" }}
				</button>

			</div>
		</template>

		<template v-if="mobile">
			<div class="mobile_track_list">
<!--				{{currentTrack}}-->

<!--				{{ track.song.id}}-->
<!--				{{playing}}-->
				<div class="item_track"
				     :class="{'playing': currentTrack !== null && currentTrack.id === track.song.id && playing}"
				     v-for="(track, index) of albumPlayList" :key="track.id"
				     @click="playTrack(track, index, album)">

					<div class="main_info">
						<div class="index" @click="(currentTrack !== null && currentTrack.id === track.song.id && playing)&&pauseTrack($event)">{{ index + 1 }}</div>
						<div class="track_deleted" v-if="track.song.deletedAt" @click="deleteTrack(track.song)"></div>
							<template v-else>
								<div class="is_purchased" v-if="track.song.isPurchased === true"></div>
								<template v-else>
									<button class="like" v-if="track.song.isFavorite === false" @click="addTrackToFavorites(track.song, album)"></button>
									<button class="like active" v-if="track.song.isFavorite === true" @click="removeTrackFromFavorites(track.song, album)"></button>
								</template>
							</template>
						<div class="track_descript">
							<span class="name" v-if="track.song">{{ track.song.title }}</span>
							<span class="artist" v-if="track">{{ track.song.artist.name }}</span>
						</div>
					</div>
					<div class="other_info">
						<span class="price"></span>
						<button type="button" class="more" @click.stop="toggleDrawerTrack(track.id)"></button>
					</div>
					<!--					<v-navigation-drawer-->
					<!--						v-if="drawerTrackID === track.id"-->
					<!--						v-model="drawerTrackID"-->
					<!--						v-touch="{right: () => swipe('Right')}"-->
					<!--						fixed-->
					<!--						right-->
					<!--						mini-variant-->
					<!--						color="#192234">-->
					<!--						<div class="wrap_drawer">-->
					<!--							<div class="wrap_drawer_list">-->
					<!--								<button type="button" class="favorite" v-if="track.song.isFavorite === false"-->
					<!--								        @click="addTrackLike(track.song, album.id)"></button>-->
					<!--								<button type="button" class="favorite active" v-if="track.song.isFavorite === true"-->
					<!--								        @click="removeTrackLike(track.song, album.id)"></button>-->
					<!--								<button type="button" class="share">Share</button>-->
					<!--								<button type="button" class="add_to_playlist">Add to Playlist</button>-->
					<!--							</div>-->
					<!--						</div>-->
					<!--					</v-navigation-drawer>-->
				</div>

			</div>
		</template>

		<thisActionStopPlay v-if="actionStopPlay" v-model="actionStopPlay"></thisActionStopPlay>
		<dialogNeedLogin v-if="needLoginDialog.visible" v-model="needLoginDialog.visible" :action="needLoginDialog.action"></dialogNeedLogin>
		<downloadTrackDialog v-if="downloadTrackDialogVisible" v-model="downloadTrackDialogVisible"></downloadTrackDialog>
		<setYouAlbumPrice v-if="setYouAlbumPriceDialogVisible" v-model="setYouAlbumPriceDialogVisible" :song="setYouSong" :album="album"
		                  :setAlbumYourPriceModalVariant="setAlbumYourPriceModalVariant" :type="'song'"></setYouAlbumPrice>
		<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>

	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import thisActionStopPlay from "@/components/dialogs/thisActionStopPlay";
import dialogNeedLogin from '@/components/dialogs/needLogin';

// mixins
import isMobile from "@/mixins/isMobile";
import addTrackToFavorites from "@/mixins/musicLike/addTrackToFavorites";
import removeTrackFromFavorites from "@/mixins/musicLike/removeTrackFromFavorites";

// const userPlayLists = () => import('@/components/userPlayLists');
const downloadTrackDialog = () => import("@/components/dialogs/downloadTrack.vue");
const setYouAlbumPrice = () => import("@/components/dialogs/setYouAlbumPrice.vue");
const errorDialog = () => import('@/components/dialogs/error');


export default {
	name: "albumPlayList",
	components: {
		thisActionStopPlay,
		dialogNeedLogin,
		// userPlayLists,
		downloadTrackDialog,
		setYouAlbumPrice,
		errorDialog
	},
	mixins: [isMobile, addTrackToFavorites, removeTrackFromFavorites],
	props: {
		detailsTrack: Boolean,
		autoPlayTrack: Number,

		album: {
			type: Object,
			default: () => ({})
		},
		albumLocation: String,
	},
	data() {
		return {
			columns: this.$store.getters.SHOW_COLUMNS_TRACK,

			currentOrderBy: {
				name: '', // bpm_desc
				key: '', // bpm
				direction: '', // desc
				active: false,
			},

			// dialogNeedLoginVisible: false,
			needLoginDialog: {
				action: null,
				visible: false,
			},
			actionStopPlay: false,

			viewMenuTrack: false,
			viewMenuPlayList: false,
			newPlayList: '',
			selectedPlaylist: [],
			viewUserPlaylistsDialog: false,

			albumIsFavorite: false,

			albumPlayList: {},
			btnShowAllTracks: false,

			dynamicKey: 0,

			// mobile
			drawerTrackID: '',
			downloadTrackDialogVisible: false,
			setYouAlbumPriceDialogVisible: false,
			setYouSong: null,
			setAlbumYourPriceModalVariant: null,
			errorDialog: false,
		};
	},
	watch: {
		album() {
			this.dynamicKey += 1;
			this.showPlayList();
		},
		PLAYED_ONLY_FAV_TRACKS() {
			this.showPlayList();
		},

		albumPlayList: {
			handler() {
				if (this.PLAYED_ONLY_FAV_TRACKS === false) {
					let isNoFavorite = this.albumPlayList.find(track => track.song.isFavorite === false);

					const allTracksAreFavorite = this.album.playList.every(track => track.song.isFavorite === true);
					const allTracksAreNoFavorite = this.album.playList.every(track => track.song.isFavorite === false);

					this.album.isFavorite = !isNoFavorite;
					if (!isNoFavorite) {
						this.album.isFavorite = true;
						if (!allTracksAreFavorite && !allTracksAreNoFavorite) {
							this.btnShowAllTracks = true;
						}


					} else {
						this.album.isFavorite = false;

						if (allTracksAreFavorite || allTracksAreNoFavorite) {
							this.btnShowAllTracks = false;

						} else if ((this.UPPER_FILTER === 'favorite' && this.CURRENT_PAGE.name === 'explore') || this.$route.params.favourite === 'favourite' || this.CURRENT_PAGE.name === 'library') {
							this.btnShowAllTracks = true;
							console.log(true, 2);
						}
					}
				}
			},
			deep: true
		},

		'album.isFavorite'() {
			if (this.album.isFavorite === true) {
				this.btnShowAllTracks = false;
			}
		},
	},
	computed: {
		...mapGetters({
			authData: 'AUTH_DATA',
			profileData: 'PROFILE',
			currentTrack: 'CURRENT_TRACK',
			currentAlbum: 'CURRENT_ALBUM',
			trackProgress: 'PROGRESS',
			playing: 'PLAYING',
			playList: 'PLAY_LIST', // OLD!
			playlists: 'PLAYLISTS',
			playlist2: 'PLAYLIST'
		}),
		...mapGetters([
			'userID',
			'ALBUM',
			'DISCOVERY',
			'SHOW_COLUMNS_TRACK',
			'PROFILE',
			'PLAYED_ONLY_FAV_TRACKS',
			'CURRENT_PAGE',

			'EXPLORE_GRID_ALBUMS',
			'FAVOURITE_MUSIC_RELEASE',
			'SEARCH_ALBUMS_RESULT',

			'FROM_PAGE',
			'UPPER_FILTER',
			'SONG_DOWNLOAD_DATA',
			'SONG_DOWNLOADED',
			'DOWNLOAD_SONG_LINK'
		]),
		// trackInPlaylist() {
		//     return Object.fromEntries(
		//         this.album.playList.map(({id}) => [
		//             id, this.playList.some(track => (track.id === id))
		//         ])
		//     );
		// },
	},
	created() {
		this.viewActionPlaylist();
	},
	mounted() {
		this.showPlayList();
		if (!this.PROFILE) {
			this.getProfile()
				.then(() => {
					// GET all Play Lists
					this.getPlaylists({id: this.profileData.id})
						.then(() => {
							console.log('saved playlists', this.playlists, {id: this.profileData.id});
						})
						.catch(err => {
							console.log(`getPlaylists, ${err}`)
						})
				})
				.catch(err => {
					console.log(`getPlaylists, ${err}`)
				})
		}
	},
	methods: {
		...mapActions({
			getProfile: 'GET_PROFILE',

			play: 'SET_PLAY',
			pause: 'SET_PAUSE',

			checkSongDownload: 'CHECK_SONG_DOWNLOAD',

			addTrackToPlaylist: 'ADD_TRACK_TO_PLAYLIST',
			addTrackStatistic: 'ADD_SONG_STATISTIC',
			getPlaylists: 'GET_PLAYLISTS',
			getPlaylist: 'GET_PLAYLIST',
			createPlaylist: 'CREATE_PLAYLIST',
			addASongToBasket: 'ADD_TO_BASKET',
			removeSongFromBasket: 'REMOVE_FROM_BASKET',
			getDownloadSongLink: 'GET_DOWNLOAD_SONG_LINK',
			hideTrack: 'HIDE_DELETED_SONG',
		}),
		redirectToArtistPage(artistId, artistName) {
			// const name = artistName.trim().replace(/ /g, '-');
			this.$router.push({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
		},
        getSongKey(song) {
            if (song.key && song.key.code) {
                return song.key.code
            }

            return '-';
        },
        addTrackToUserPlaylist() {
			this.viewUserPlaylistsDialog = true;
		},
		showPlayList() {
			this.albumIsFavorite = this.album.isFavorite === true;
			if (this.PLAYED_ONLY_FAV_TRACKS === true) {
				if (this.albumIsFavorite === false) {
					this.albumPlayList = this.album.playList.filter(track => track.song.isFavorite === true);
					if (this.detailsTrack) {
						// this.orderBy(this.currentOrderBy.key, this.currentOrderBy.direction);
					}
				} else {
					this.albumPlayList = this.album.playList;
					if (this.detailsTrack) {
						// this.orderBy(this.currentOrderBy.key, this.currentOrderBy.direction);
					}
				}
			} else {
				this.albumPlayList = this.album.playList;
				if (this.detailsTrack) {
					// this.orderBy(this.currentOrderBy.key, this.currentOrderBy.direction);
				}
			}
		},

		toggleViewTracks() {
			console.log('toggleViewTracks');
			// hide no-favorite tracks
			if (this.PLAYED_ONLY_FAV_TRACKS === false && this.$store.getters.CURRENT_TRACK !== null && this.$store.getters.CURRENT_TRACK.isFavorite === false && this.currentAlbum.id === this.album.id) {
				this.actionStopPlay = true;
			} else {
				if (this.PLAYED_ONLY_FAV_TRACKS === true) {
					this.$store.dispatch('SET_PLAYED_ONLY_FAV_TRACKS', false);
				} else {
					this.$store.dispatch('SET_PLAYED_ONLY_FAV_TRACKS', true);
				}
			}
		},

		viewActionPlaylist() {
			const allTracksAreFavorite = this.album.playList.every(track => track.song.isFavorite === true);
			const allTracksAreNoFavorite = this.album.playList.every(track => track.song.isFavorite === false);

			if (allTracksAreFavorite || allTracksAreNoFavorite) {
				this.$store.dispatch('SET_PLAYED_ONLY_FAV_TRACKS', false);
				this.btnShowAllTracks = false;
			} else {
				if (((this.UPPER_FILTER === 'favorite' && this.CURRENT_PAGE.name === 'explore') || this.$route.params.favourite === 'favourite' || this.CURRENT_PAGE.name === 'library') && this.album.isFavorite !== true) {
					this.btnShowAllTracks = true;
				}

				if ((this.UPPER_FILTER === 'favorite' && this.CURRENT_PAGE.name === 'explore') || this.$route.params.favourite === 'favourite') {
					this.$store.dispatch('SET_PLAYED_ONLY_FAV_TRACKS', true);
				} else {
					this.$store.dispatch('SET_PLAYED_ONLY_FAV_TRACKS', false);
				}
			}
		},
		pauseTrack(event){
			event.stopPropagation();
			this.pause();
		},
		playTrack(track, index, album) {
			// if (!this.album.playList) {
			//     this.getFullAlbum(this.musicRelease.id)
			//         .then(() => {
			//             this.musicRelease = this.FULL_ALBUM;
			//             this.runPlay();
			//         });
			// }
			
			// Check if there's an error state in the player before playing
			if (this.$store.state.hlsPlayer.soundError) {
				console.log("Detected player in error state, attempting to recover before playing");
				
				// Handle the error and reset player state
				this.$store.dispatch('HANDLE_TRACK_ERROR').then(() => {
					// After resetting, try to play the track with a small delay
					setTimeout(() => {
						this.playTrackAfterReset(track, index, album);
					}, 200);
				}).catch(error => {
					console.error("Failed to handle track error:", error);
					// Still try to play the track as a fallback
					this.playTrackAfterReset(track, index, album);
				});
			} else {
				// No error state, play normally
				this.playTrackAfterReset(track, index, album);
			}
		},
		
		playTrackAfterReset(track, index, album) {
			// Check if the track has a valid link before attempting to play
			if (!track.song.link) {
				console.warn("Track has no link, cannot play:", track.song.title);
				return;
			}
			
			this.play({
				fromPlay: 'album_default',
				track: track.song,
				index: index,
				album: this.album,
				isDiscoveryStatus: this.DISCOVERY
			});
			if (this.authData.accessToken) {
				this.$store.commit('SET_IS_LISTENED_ALBUM', album);
			}
		},

		// ORDER-BY album playList (bpm, key)
		orderBy: function (key, direction) {
			this.currentOrderBy.active = true;

			this.currentOrderBy.name = `${key}_${direction}`;
			this.currentOrderBy.key = key;
			this.currentOrderBy.direction = direction;

			if (this.currentOrderBy.key === 'bpm') {
				if (this.currentOrderBy.direction === 'asc') {
					this.albumPlayList.sort((a, b) => (a.song.bpm >= b.song.bpm) ? 1 : -1);
				}
				if (this.currentOrderBy.direction === 'desc') {
					this.albumPlayList.sort((a, b) => (a.song.bpm <= b.song.bpm) ? 1 : -1);
				}
			}
			if (this.currentOrderBy.key === 'key') {
				if (this.currentOrderBy.direction === 'asc') {
					this.albumPlayList.sort((a, b) => (a.song.key >= b.song.key) ? 1 : -1);
				}
				if (this.currentOrderBy.direction === 'desc') {
					this.albumPlayList.sort((a, b) => (a.song.key <= b.song.key) ? 1 : -1);
				}
			}
			setTimeout(() => {
				this.currentOrderBy.active = false;
			}, 500);
		},

		checkColumn() {
			this.$store.commit('SET_SHOW_COLUMNS_TRACK', this.columns);
		},

		addSongToCart(song) {
			if (this.authData.accessToken) {
				song.inCart = true;
				let data = {
					productId: song.id,
					type: 'song'
				};
				this.addASongToBasket(data)
					.then(() => {

					})
					.catch(err => console.error(`addSongToCart, ${err}`))
			} else {
				// this.dialogNeedLoginVisible = true;
				this.needLoginDialog = {
					action: 'add_son_to_cart',
					visible: true,
				}
			}
		},
		openCart() {
			if (this.authData.accessToken) {
				this.$store.commit('SET_HEADER_SHOPPING_CART', false);
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			} else {
				// this.dialogNeedLoginVisible = true;
				this.needLoginDialog = {
					action: 'open_cart',
					visible: true,
				}
			}
		},

		setYouAlbumPrice(song, variant) {
			if (this.authData.accessToken) {
				this.setAlbumYourPriceModalVariant = variant;
				this.setYouAlbumPriceDialogVisible = true;
				this.setYouSong = song;
			} else {
				// this.dialogNeedLoginVisible = true;
				this.needLoginDialog = {
					action: 'set_you_price',
					visible: true,
				}
			}
		},


		checkPossibilityDownloadTrack(track) {
			let trackID = track.id;
			let trackArtist = '';
			if (track.artist && track.artist.name) {
				trackArtist = track.artist.name;
			} else {
				trackArtist = '';
			}
			let trackTitle = track.title;

			if (this.authData.accessToken) {
				this.$store.commit('SET_SONG_DOWNLOAD_DATA', {id: trackID, artist: trackArtist, title: trackTitle});

                if (track.isPurchased === true || (this.album.owner && this.album.owner.id === this.userID)) {
					this.directDownload();
				} else {
					this.checkSongDownload()
						.then(() => {
							let remain = this.SONG_DOWNLOADED.limit - this.SONG_DOWNLOADED.downloaded;
							if (remain <= 10) {
								this.downloadTrackDialogVisible = true;
							} else {
								this.directDownload();
							}
						})
						.catch(err => console.error(`checkPossibilityDownloadTrack, ${err}`));
				}
			} else {
				// this.dialogNeedLoginVisible = true;
				this.needLoginDialog = {
					action: 'download_track',
					visible: true,
				}
			}
		},
		directDownload() {
			this.getDownloadSongLink(this.SONG_DOWNLOAD_DATA.id)
				.then(() => {
					if (this.DOWNLOAD_SONG_LINK) {
						let link = document.createElement('a');
						link.href = this.DOWNLOAD_SONG_LINK;
						link.download = `${this.SONG_DOWNLOAD_DATA.artist} - ${this.SONG_DOWNLOAD_DATA.name}.flac`;
						link.click();
					} else {
						this.errorDialog = true;
					}
				})
				.catch(err => console.error(`checkPossibilityDownloadTrack, ${err}`));
		},

		deleteTrack(track) {
	        let deletedTrackIndex = this.albumPlayList.findIndex((el) => el.song.id === track.id);
	        if (deletedTrackIndex !== -1) {
	            this.albumPlayList.splice(deletedTrackIndex, 1);
				this.hideTrack(track.id);
	        }
		},


		//mobile
		toggleDrawerTrack(trackID) {
			this.drawerTrackID = trackID;
		},
		swipe(direction) {
			if (direction === 'Right') {
				// this.drawerTrack = false;
			}
		},
	},
};
</script>

<style lang="scss">

</style>
