<template>
  <div class="unavailable-container" :class="{ 'with-timer': timerEnd && !isExpired }">
    <div v-if="!timerEnd || isExpired">Unavailable</div>
    <div v-else>
      <div>Unavailable in</div>
      <div class="remaining-time">{{ remainingTime }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnavailableLabel",
  props: {
    timerEnd: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      remainingTime: "",
      timerInterval: null,
    };
  },
  computed: {
    isExpired() {
      return this.timerEnd && new Date(this.timerEnd) <= new Date();
    }
  },
  watch: {
    timerEnd: {
      immediate: true,
      handler() {
        if (this.timerEnd) {
          this.updateRemainingTime();
          this.timerInterval = setInterval(this.updateRemainingTime, 60000);
        }
      },
    },
  },
  beforeUnmount() {
    clearInterval(this.timerInterval);
  },
  methods: {
    updateRemainingTime() {
      if (!this.timerEnd) return;

      const now = new Date();
      const endTime = new Date(this.timerEnd);
      const diff = endTime - now;

      if (diff <= 0) {
        this.remainingTime = "";
        clearInterval(this.timerInterval);
        return;
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

      this.remainingTime = `${days} Days ${hours} Hours ${minutes} Minutes`;
    },
  },
};
</script>

<style lang="scss" scoped>
.unavailable-container {
  font-family: Roboto, sans-serif;
  background-color: #0C1324;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  position: absolute;
  top: 70px;

  &.with-timer {
    background-color: #E94E4E;
    font-size: 14px;

    .remaining-time {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}
</style>
