import Hls from "hls.js";

/**
 * Handles HLS network errors with automatic retry mechanism
 * @param {Object} state - The Vuex state object
 * @param {Object} store - Vuex store instance for dispatching actions
 * @param {Object} data - Error data from HLS.js
 */
export function handleNetworkError(state, store, data) {
    console.log("Network error occurred", data.details);
    try {
        if (state.sound) state.sound.pause();
    } catch (e) {
        console.warn("Error pausing sound:", e);
    }
    
    // Show error state immediately
    state.soundError = true;
    state.retryStatus = 'Attempting to recover...';
    
    // Attempt retries in the background
    if (state.retryAttempts < 3) {
        console.log(`Retry attempt ${state.retryAttempts + 1} of 3`);
        state.retryAttempts++;
        state.retryStatus = `Retry ${state.retryAttempts} of 3...`;
        
        setTimeout(() => {
            if (state.hlsInstance) {
                try {
                    state.hlsInstance.loadSource(state.currentTrack.link);
                    state.hlsInstance.attachMedia(state.sound);
                } catch (e) {
                    console.warn("Error reloading source:", e);
                }
            }
        }, state.retryAttempts * 1000);
        
    } else {
        // Reset retry counter
        state.retryAttempts = 0;
        state.retryStatus = 'Recovery failed. Moving to next track...';
        
        // Ensure proper cleanup before skipping
        cleanupHlsInstance(state);
        
        // Wait a small amount of time to ensure cleanup is complete
        setTimeout(() => {
            // Auto skip to next track after all retries fail
            console.log("All retry attempts failed, skipping to next track");
            state.soundError = false;
            store.dispatch('SKIP_TRACK', 'next');
        }, 100);
    }
}

/**
 * Handles HLS media errors with automatic recovery mechanism
 * @param {Object} state - The Vuex state object
 * @param {Object} store - Vuex store instance for dispatching actions
 * @param {Object} data - Error data from HLS.js
 */
export function handleMediaError(state, store, data) {
    console.log("Media error occurred", data.details);
    try {
        if (state.sound) state.sound.pause();
    } catch (e) {
        console.warn("Error pausing sound:", e);
    }
    
    // Show error state immediately
    state.soundError = true;
    state.retryStatus = 'Attempting to recover...';
    
    // Attempt retries in the background
    if (state.retryAttempts < 3) {
        console.log(`Retry attempt ${state.retryAttempts + 1} of 3`);
        state.retryAttempts++;
        state.retryStatus = `Retry ${state.retryAttempts} of 3...`;
        try {
            state.hlsInstance.recoverMediaError();
        } catch (e) {
            console.warn("Error recovering media:", e);
        }
    } else {
        // Reset retry counter
        state.retryAttempts = 0;
        state.retryStatus = 'Recovery failed. Moving to next track...';
        
        // Ensure proper cleanup before skipping
        cleanupHlsInstance(state);
        
        // Wait a small amount of time to ensure cleanup is complete
        setTimeout(() => {
            // Auto skip to next track after all retries fail
            console.log("All retry attempts failed, skipping to next track");
            state.soundError = false;
            store.dispatch('SKIP_TRACK', 'next');
        }, 100);
    }
}

/**
 * Handles other fatal HLS errors
 * @param {Object} state - The Vuex state object
 * @param {Object} store - Vuex store instance for dispatching actions
 * @param {Object} data - Error data from HLS.js
 */
export function handleOtherError(state, store, data) {
    console.log("An error occurred", data);
    try {
        if (state.sound) state.sound.pause();
    } catch (e) {
        console.warn("Error pausing sound:", e);
    }
    
    state.soundError = true;
    state.retryStatus = 'Unrecoverable error. Moving to next track...';
    
    // Ensure proper cleanup before skipping
    cleanupHlsInstance(state);
    
    setTimeout(() => {
        state.soundError = false;
        store.dispatch('SKIP_TRACK', 'next');
    }, 1500);
}

/**
 * Properly cleans up the HLS instance and audio element
 * @param {Object} state - The Vuex state object 
 */
export function cleanupHlsInstance(state) {
    if (state.hlsInstance) {
        try {
            // Detach media first to prevent events from firing during cleanup
            state.hlsInstance.detachMedia();
            // Remove all event listeners
            state.hlsInstance.removeAllListeners();
            // Then destroy
            state.hlsInstance.destroy();
            state.hlsInstance = null;
        } catch (e) {
            console.warn("Error destroying HLS instance:", e);
            state.hlsInstance = null;
        }
    }
    
    if (state.sound) {
        try {
            // Instead of replacing the audio element, just reset its state
            // This preserves iOS permission to play audio
            const audio = state.sound;
            
            // Remove all event listeners (using a more compatible approach)
            const newAudio = audio.cloneNode(false);
            
            // Preserve the original element but copy properties from the new one
            audio.src = '';
            audio.removeAttribute('src');
            audio.load();
            
            // We keep the original element, not replacing it
        } catch (e) {
            console.warn("Error cleaning up audio element:", e);
        }
    }
}

/**
 * Creates the HLS configuration with proper logging
 * @returns {Object} HLS configuration object
 */
export function createHlsConfig() {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    
    // Create a proper logger that wraps console methods
    // Must match all methods HLS controllers might call
    const hlsLogger = {
        debug: (...args) => {},  // Disable debug logs completely
        log: (...args) => {},    // Disable regular logs
        info: (...args) => {},   // Disable info logs
        warn: (...args) => {},   // Disable warning logs
        error: (...args) => console.error('[HLS]', ...args) // Only keep error logs
    };
    
    // Set up a global error handler to catch and suppress HLS.js errors
    // This is in addition to the logger, as a safety net
    setupGlobalErrorHandler();
    
    return {
        maxMaxBufferLength: isIOS ? 30 : 60,
        maxBufferLength: isIOS ? 15 : 30,
        maxBufferSize: isIOS ? 30 * 1000 * 1000 : 60 * 1000 * 1000,
        maxBufferHole: 0.5,
        lowLatencyMode: true,
        debug: false,
        // Disable web workers on iOS for better compatibility
        enableWorker: !isIOS,
        // Smaller fragments and faster level switching for iOS
        fragLoadingTimeOut: isIOS ? 20000 : 60000,
        startFragPrefetch: isIOS,
        testBandwidth: !isIOS,
        progressive: isIOS,
        logger: hlsLogger,
        // Additional settings to minimize logging
        enableLogs: false,
        logLevel: "error"
    };
}

/**
 * Sets up a global error handler to catch and suppress specific HLS.js errors
 */
function setupGlobalErrorHandler() {
    // Store the original console.error method
    const originalConsoleError = console.error;
    
    // Override console.error to filter out specific HLS.js errors
    console.error = function(...args) {
        // Check if args is empty or contains undefined values
        if (!args || !args.length) {
            // If there's nothing to log, just return
            return originalConsoleError.apply(console, args);
        }

        try {
            // Check if this is the specific HLS.js error we want to suppress
            const errorMessage = args.filter(arg => arg !== undefined && arg !== null).join(' ');
            if (
                errorMessage.includes('this.log is not a function') ||
                errorMessage.includes('this.warn is not a function') ||
                errorMessage.includes('TypeError: this.log is not a function') ||
                errorMessage.includes('TypeError: this.warn is not a function') ||
                (errorMessage.includes('hls.mjs') && errorMessage.includes('onMediaSeeking')) ||
                (errorMessage.includes('hls.mjs') && errorMessage.includes('onMediaSeeked'))
            ) {
                // Suppress the error by not passing it to the original console.error
                return;
            }
        } catch (e) {
            // If any error occurs during filtering, just use the original error handler
        }
        
        // For all other errors, use the original console.error method
        originalConsoleError.apply(console, args);
    };
    
    // Also handle uncaught exceptions to make sure we catch the HLS.js errors
    window.addEventListener('error', function(event) {
        if (
            (event.message && (
                event.message.includes('this.log is not a function') ||
                event.message.includes('this.warn is not a function'))
            ) ||
            (event.filename && event.filename.includes('hls.mjs') && 
             event.error && event.error.stack && (
                event.error.stack.includes('onMediaSeeking') || 
                event.error.stack.includes('onMediaSeeked')
             ))
        ) {
            // Prevent the error from being displayed in the console
            event.preventDefault();
            return true;
        }
        // Let other errors propagate normally
        return false;
    }, true);
}

/**
 * Sets up error event handling for the HLS instance
 * @param {Object} hlsInstance - The HLS.js instance
 * @param {Object} state - The Vuex state object
 * @param {Object} store - Vuex store instance
 */
export function setupErrorHandling(hlsInstance, state, store) {
    hlsInstance.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
            switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                    handleNetworkError(state, store, data);
                    break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                    handleMediaError(state, store, data);
                    break;
                default:
                    handleOtherError(state, store, data);
                    break;
            }
        }
    });
} 