<template>
  <v-dialog
    :value="value"
    @input="$emit('input')"
    :max-width="maxWidth"
    persistent>
    <div class="dialog-wrapper beta-testing-dialog">
      <div class="dialog-header">
        <h2>{{ title }}</h2>
      </div>
      <div class="dialog-content">
        <p class="mb-4">{{ description }} If you would like to get access contact <a :href="'mailto:' + contactEmail">{{ contactEmail }}</a></p>
        <div class="input-style-3">
          <input
            v-model="enteredPhrase"
            type="password"
            @keydown.enter="verifyPassphrase"
            :placeholder="inputPlaceholder"
          />
          <span v-if="errorMessage" class="error-message">{{ errorMessage }}</span>
        </div>
      </div>
      <div class="dialog-actions">
        <button 
          type="button" 
          class="tetriatary_btn medium" 
          @click="closeDialog"
          v-ripple
        >
          {{ cancelButtonText }}
        </button>
        <button 
          type="button" 
          class="c2a_btn medium" 
          @click="verifyPassphrase"
          :disabled="loading"
          v-ripple
        >
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="20"
            width="2"
            color="white"
            class="mr-2"
          ></v-progress-circular>
          {{ verifyButtonText }}
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'BetaTestingDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Access Required'
    },
    description: {
      type: String,
      default: 'This functionality is currently accessible to beta testers only.'
    },
    contactEmail: {
      type: String,
      default: 'admin@psychurch.org'
    },
    inputPlaceholder: {
      type: String,
      default: 'Access Code'
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    verifyButtonText: {
      type: String,
      default: 'Verify'
    },
    maxWidth: {
      type: [Number, String],
      default: 400
    },
    storageKey: {
      type: String,
      required: true
    },
    passphrase: {
      type: String,
      required: true,
      validator: function(value) {
        return value.length > 0;
      }
    }
  },
  data() {
    return {
      enteredPhrase: '',
      errorMessage: '',
      loading: false
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    verifyPassphrase() {
      this.loading = true
      this.errorMessage = ''
      
      // Simulate a small delay for security
      setTimeout(() => {
        if (this.enteredPhrase.trim() === this.passphrase) {
          // Store verification in localStorage to persist across page reloads
          localStorage.setItem(this.storageKey, 'true')
          this.$emit('verified')
          this.closeDialog()
        } else {
          this.errorMessage = 'Incorrect access code. Please try again.'
        }
        this.loading = false
      }, 500)
    },
    closeDialog() {
      this.enteredPhrase = ''
      this.errorMessage = ''
      this.dialog = false
      this.$emit('cancelled')
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/mixins/dialog-mixins";

.beta-testing-dialog {
  @include dialog((
    has-header: true,
    has-content: true,
    has-actions: true,
    content-bordered: true
  ));

  .dialog-content {
    display: flex;
    flex-direction: column;
    
    p {
      margin-bottom: 16px;
      font-size: 16px;
      text-align: center;

      a {
        color: #20C4F5;
        text-decoration: none;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .error-message {
      color: #E94E4E;
      font-size: 14px;
      margin-top: 8px;
    }
  }

  .dialog-actions {
    button {
      .v-progress-circular {
        margin-right: 8px;
      }
    }
  }
}
</style> 