<template>
	<div
	  class="item_grid_album"
	  @click="redirectToAlbum(musicRelease)"
	  :class="{
		'playing_album': isCurrentPlaying || playing,
		'loadingAlbum': LOADING_RELEASE_ID && LOADING_RELEASE_ID === musicRelease.id,
		'is_listened': musicRelease.isListened,
		'deleted': currentlyUnavailable && !musicRelease.realStatus.timerEnd,
		'small': small,
		'highlight-recent': highlightRecent
	  }"
	  :ref="`album-${musicRelease.id}`"
	  :data-album-id="musicRelease.id"
	>
	  <div class="album_cover" :class="{'no_img': !musicRelease.smallImage}">
		<!-- Skeleton Loader for Album Image -->
		<div v-show="!albumImageLoaded[musicRelease.id]" class="skeleton-loader default_grid_albums">
		  <div class="v-skeleton-loader__image"></div>
		</div>

		<!-- Image element with @load handler -->
		<img
		  :src="musicRelease.smallImage || musicRelease.image"
		  alt=""
		  @load="onImageLoad(musicRelease.id)"
		  loading="lazy"
		  :class="{ 'image-loaded': albumImageLoaded[musicRelease.id] }"
		>

		<!-- Active overlay appears after image load -->
		<div
		  v-show="albumImageLoaded[musicRelease.id]"
		  class="active_overlay"
		  :class="{
			'active_like': musicRelease.isFavorite === true,
			'purchased': musicRelease.isPurchased && musicRelease.isPurchased === true
		  }"
		>
		  <template v-if="!musicRelease.isPurchased || musicRelease.isPurchased !== true">
			<template v-if="!hideLike">
			  <!-- Desktop like button -->
			  <button
				type="button"
				class="like"
				v-if="!musicRelease.isFavorite"
				@click.stop="handleLikeClick"
			  ></button>

			  <button
				type="button"
				:class="['like active', {
				  'removing': isRemoving,
				  'animate-like': isLikeAnimating
				}]"
				v-else
				@click.stop="handleLikeClick"
			  >
				<div class="particles" v-if="isLikeAnimating">
				  <div class="particle" v-for="n in 8" :key="n"></div>
				</div>
				<div class="break-particles" v-if="isRemoving">
				  <div class="break-particle" v-for="n in 2" :key="n"></div>
				</div>
			  </button>

			  <!-- Mobile like button -->
			  <button
				type="button"
				:class="['mobile_like', {
				  'active': musicRelease.isFavorite,
				  'removing': isRemoving,
				  'animate-like': isLikeAnimating
				}]"
				@click.stop="handleLikeClick"
			  ></button>
			</template>
		  </template>
		  <template v-else>
			<div class="release_purchased"></div>
		  </template>

		  <template v-if="!currentlyUnavailable">
        <div class="mobile_actions" :class="{ 'is-loading': LOADING_RELEASE_ID && LOADING_RELEASE_ID === musicRelease.id }">
          <div class="play" v-if="!isCurrentPlaying || (isCurrentPlaying && !playing)"
             v-show="!(LOADING_RELEASE_ID && LOADING_RELEASE_ID === musicRelease.id)"
             @click.stop="playAlbum">
          <span class="material-icons">play_arrow</span>
          </div>
          <div class="pause" v-if="LOADING_RELEASE_ID && LOADING_RELEASE_ID === musicRelease.id">
          <span class="material-icons">hourglass_empty</span> Loading
          </div>
          <div class="pause" v-if="isCurrentPlaying && playing && !(LOADING_RELEASE_ID && LOADING_RELEASE_ID === musicRelease.id)">
          <span class="material-icons">volume_up</span> Playing
          </div>
        </div>

        <button class="play_album" v-if="!isCurrentPlaying" @click.stop="playAlbum" :class="[isCurrentPlaying ? 'current' : 'not_current']">
          <span class="material-icons">play_arrow</span>
        </button>
        <button class="play_album" v-if="isCurrentPlaying && !playing" @click.stop="play" :class="[isCurrentPlaying ? 'current' : 'not_current']">
          <span class="material-icons">play_arrow</span>
        </button>

        <button type="button" class="pause_album" v-if="isCurrentPlaying && playing || LOADING_RELEASE_ID && LOADING_RELEASE_ID === musicRelease.id"
            @click.stop="pause">
          <span></span>
          <v-progress-circular
          v-if="FULL_ALBUM_LOADING && !isCurrentPlaying"
          :size="55"
          :width="7"
          indeterminate
          color="#20C4F5">
          </v-progress-circular>
        </button>
		  </template>

      <template v-if="currentlyUnavailable">
        <UnavailableLabel :timerEnd="musicRelease.realStatus.timerEnd" />
        <button type="button"
                v-if="!musicRelease.realStatus.timerEnd"
                class="btn_currently_unavailable"
                @click.stop="removeRelease(musicRelease.id)"
                v-ripple>
          Remove
        </button>
      </template>
		</div>
	  </div>

	  <div class="album_name">{{ musicRelease.title }}</div>
	  <div class="album_artist" v-if="!hideArtist" @click.stop="redirectToArtistPage(musicRelease)">
		<template v-if="musicRelease.artist">
		  <span>by</span>
		  {{ musicRelease.artist.name }}
		</template>
	  </div>

	  <dialogNeedLogin v-if="needLoginDialog.visible" v-model="needLoginDialog.visible" :action="needLoginDialog.action"></dialogNeedLogin>
	</div>
  </template>

  <script>
  import {mapActions, mapGetters} from 'vuex';
  import addAlbumToFavorites from "@/mixins/musicLike/addAlbumToFavorites";
  import removeAlbumFromFavorites from "@/mixins/musicLike/removeAlbumFromFavorites";
  import UnavailableLabel from "@/components/musicReleases/UnavailableLabel.vue";

  const dialogNeedLogin = () => import('@/components/dialogs/needLogin');

  export default {
    name: "albumGrid",
    props: ['album', 'small', 'discoveryStatus', 'isFavouriteSong', 'hideArtist', 'playOnlyLikedTracks', 'hideLike', 'playZone'],
    components: {
      UnavailableLabel,
      dialogNeedLogin,
    },
    mixins: [
      addAlbumToFavorites,
      removeAlbumFromFavorites
    ],
    data() {
      return {
      musicRelease: this.album,
      albumImageLoaded: {}, // Object to track image loading state for each album
      showAllTracks: false,
      needLoginDialog: {
        action: null,
        visible: false,
      },
      playListLoading: false,
      currentlyUnavailable: false,
      unavailableTimer: null,
      isRemoving: false,
      isLikeAnimating: false,
      debugScroll: true, // Match search page debug setting
      highlightRecent: false,
      }
    },
    computed: {
      ...mapGetters({
      authData: 'AUTH_DATA',
      playing: 'PLAYING',
      currentAlbum: 'CURRENT_ALBUM',
      albumExpanded: 'ALBUM_EXPANDED',
      }),
      ...mapGetters([
      'ALBUM', 'EXPLORE_GRID_ALBUMS', 'DISCOVERY', 'CURRENT_PAGE', 'PLAYED_ONLY_FAV_TRACKS',
      'UPPER_FILTER', 'ALBUM_LOADING', 'FULL_ALBUM_LOADING', 'FULL_ALBUM', 'LOADING_RELEASE_ID',
      'GRID_ALBUM_SCROLL_ID'
      ]),
      isCurrentPlaying() {
      return !!this.currentAlbum && this.currentAlbum.id === this.album.id;
      },
    },
    created() {
      let status = this.musicRelease?.realStatus?.value || this.musicRelease.status;

      if (status === 'unavailable' && !this.musicRelease?.realStatus?.timerEnd) {
        this.currentlyUnavailable = true;
      } else if (status === 'unavailable' && this.musicRelease?.realStatus?.timerEnd) {
        this.currentlyUnavailable = true;
        this.unavailableTimer = this.calculateTimeLeft(this.musicRelease.realStatus.timerEnd);
        console.log('unavailableTimer', status, this.unavailableTimer, this.musicRelease);
      }
      if (this.UPPER_FILTER === 'favorite' && this.CURRENT_PAGE.name === 'explore') {
      this.$store.dispatch('SET_PLAYED_ONLY_FAV_TRACKS', true);
      }
    },
    mounted() {
      // Try to restore scroll position from filter state first
      try {
        const savedState = sessionStorage.getItem('exploreFilterState');
        if (savedState) {
          const state = JSON.parse(savedState);
          if (state.scrollPosition && state.albumId === this.musicRelease.id) {
            console.log('Restoring to saved position:', state.scrollPosition);
            window.scrollTo(0, state.scrollPosition);
            return; // Skip the rest of scroll handling if we restored position
          }
        }
      } catch (error) {
        console.error('Error restoring scroll position:', error);
      }

      // Fall back to scrolling to album if needed
      const currentAlbum = this.$refs[`album-${this.GRID_ALBUM_SCROLL_ID}`];
      let currentRoute = this.$router.history.current.name;

      if (currentAlbum && (currentRoute === 'explore' || currentRoute === 'subgenre' || currentRoute === 'searchDetails')) {
        this.$nextTick(() => {
          currentAlbum.scrollIntoView({
            block: 'center'
          });

          // Add highlight animation if this is the last interacted element
          const lastInteractedId = sessionStorage.getItem('lastInteractedElement');
          if (lastInteractedId && lastInteractedId === `album-${this.musicRelease.id}`) {
            this.highlightRecent = true;
            // Remove highlight after animation
            setTimeout(() => {
              this.highlightRecent = false;
              sessionStorage.removeItem('lastInteractedElement');
            }, 2000);
          }
        });
      }
    },
    methods: {
      // Handle image loading for each album
      onImageLoad(albumId) {
      // Use nextTick to ensure DOM update before changing the state
      this.$nextTick(() => {
        this.$set(this.albumImageLoaded, albumId, true);
      });
      },
      ...mapActions({
      play: 'SET_PLAY',
      pause: 'SET_PAUSE',
      getAlbum: 'GET_ALBUM',
      getFullAlbum: 'GET_FULL_ALBUM',
      removeReleaseFromFavorites: 'REMOVE_ALBUM_FROM_FAVORITES',
      }),
      redirectToArtistPage(musicRelease) {
      let artistID = musicRelease.artist.id;
      let artistName = musicRelease.artist.identifier;
      let musicReleaseID = musicRelease.id;

      // Save the current element as last interacted
      sessionStorage.setItem('lastInteractedElement', `album-${musicReleaseID}`);

      // Save filter state before navigation
      const albumsFilter = this.$parent.$parent.$refs.albumsFilter;
      if (albumsFilter) {
        albumsFilter.saveFilterState();
      }

      this.$store.commit('SET_GRID_ALBUM_SCROLL_ID', musicReleaseID);
      this.$router.push({
        name: 'artist-profile', params: {
        id: artistID,
        name: window.toHumanFormat(artistName)
        }
      });
      },
      playAlbum() {
      let params = { id: this.musicRelease.id, title: this.musicRelease.title };
      this.getFullAlbum(params).then(() => {
        this.musicRelease = this.FULL_ALBUM;
        this.runPlay();
      });
      },
      runPlay() {
      if (!this.isCurrentPlaying) {
        let albumToPlay;

        if (this.playOnlyLikedTracks === true && !this.musicRelease.isPurchased) {
        albumToPlay = this.musicRelease.playList.filter(track => track.song.isFavorite === true);
        this.$store.commit('SET_PLAYED_ONLY_FAV_TRACKS', true);
        } else {
        albumToPlay = this.musicRelease.playList.filter(track => !track.song.deletedAt && track.song.link);
        this.$store.commit('SET_PLAYED_ONLY_FAV_TRACKS', false);
        }

        this.$store.dispatch('SET_PLAYED_ZONE', this.playZone);

        this.play({
        fromPlay: 'album_default',
        track: albumToPlay[0].song,
        index: 0,
        album: this.musicRelease,
        isDiscoveryStatus: this.discoveryStatus
        });
        this.playListLoading = false;
      }
      },
      removeRelease(albumID) {
      this.$store.dispatch('REMOVE_RELEASE_FROM_COLLECTIONS', albumID);
      this.removeReleaseFromFavorites({ 'music_release_id': albumID }).then(() => {
        // Handle any post-removal actions here
      });
      },
      async handleLikeClick() {
      if (this.musicRelease.isFavorite) {
        this.isRemoving = true;
        await new Promise(resolve => setTimeout(resolve, 400));
        await this.removeAlbumFromFavorites(this.musicRelease);
        this.isRemoving = false;
      } else {
        await this.addAlbumToFavorites(this.musicRelease);
        this.$nextTick(() => {
        this.isLikeAnimating = true;
        setTimeout(() => {
          this.isLikeAnimating = false;
        }, 800);
        });
      }
      },
      onLikeAnimationEnd() {
        this.isLikeAnimating = false;
      },

      // small refactoring part

      redirectToAlbum(musicRelease) {
        if (!this.isAlbumAccessible(musicRelease)) {
          return;
        }

        this.saveAlbumInteraction(musicRelease.id);
        this.saveFilterState();
        this.saveScrollPosition();

        this.$store.commit('SET_GRID_ALBUM_SCROLL_ID', musicRelease.id);
        this.$router.push({
          name: 'albumDetails',
          params: {
            id: musicRelease.id,
            name: window.toHumanFormat(musicRelease.identifier),
            favourite: this.isFavouriteSong
          }
        });
      },

      // checks if an album is available for viewing
      isAlbumAccessible(musicRelease) {
        const { deletedAt, status, havePurchased, realStatus } = musicRelease;
        const { value: realStatusValue, timerEnd } = realStatus || {};

        return (
            (!deletedAt && status !== 'draft') || // is not deleted and not in draft
            (deletedAt && havePurchased) || // deleted but have purchased -  remain accessible
            (realStatusValue === 'unavailable' && timerEnd) // unavailable with a valid countdown timer
        );
      },

      // save ID about the last interaction with the album
      saveAlbumInteraction(albumId) {
        sessionStorage.setItem('lastInteractedElement', `album-${albumId}`);
      },

      // save the filter state before the transition
      saveFilterState() {
        const albumsFilter = this.$parent?.$parent?.$refs?.albumsFilter;
        if (albumsFilter) {
          albumsFilter.saveFilterState();
        }
      },

      // save the current scroll position before moving
      saveScrollPosition() {
        const currentPosition = window.scrollY;
        sessionStorage.setItem('searchPageScrollPosition', currentPosition);
      },

      calculateTimeLeft(timerEnd) {
        const endTime = new Date(timerEnd).getTime();
        const now = new Date().getTime();
        const timeDiff = endTime - now;

        if (timeDiff <= 0) {
          return null; // Таймер истек
        }

        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        return { days, hours, minutes };
      }
    }
  }
  </script>

  <style scoped>
  .album_cover {
	position: relative;
	width: 100%;
	padding-bottom: 100%; /* Creates a 1:1 aspect ratio */
	overflow: hidden;
	border-radius: 12px;
	/* Setup for gradient border using double background technique */
	border: double 1px transparent; /* Creates space for the border */
	background-image: linear-gradient(#0C192A, #0C192A), /* Inner background */
					  linear-gradient(#0C192A, #0C192A); /* Border background (initially hidden) */
	background-origin: border-box;
	background-clip: padding-box, border-box; /* Clips inner background to padding, border */
  }

  @media (max-width: 479px) {
	.album_cover {
	  border-width: 1.5px;
	}

	.v-skeleton-loader__image {
	  height: 170px;
	}
  }

  .item_grid_album:has(.current) .album_cover,
  .item_grid_album:has(.pause_album) .album_cover {
	background: linear-gradient(#0C192A, #0C192A), /* Inner solid background */
					linear-gradient(90deg,
					  #20C4F5,
					  #9C1BB1,
					  #20C4F5,
					  #9C1BB1,
					  #20C4F5,
					  #9C1BB1,
					  #20C4F5
					);
	background-origin: border-box;
	background-clip: padding-box, border-box;
	background-size: 100% 100%, 600% 100%; /* Inner bg normal size, gradient much wider */
	animation: moveGradient 60s linear infinite;
	background-position: 0 0, 0% 50%;
  }

  @keyframes moveGradient {
	0% {
	  background-position: 0 0, 0% 50%;
	}
	100% {
	  background-position: 0 0, -500% 50%;
	}
  }

  .skeleton-loader.default_grid_albums {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg,
	  #1a2235 0%,
	  #243049 30%,
	  #1a2235 50%,
	  #1a2235 100%
	);
	background-size: 300% 100%;
	animation: shimmer 2s infinite;
	border-radius: 12px;
  }

  @keyframes shimmer {
	0% {
	  background-position: -600px 0;
	}
	100% {
	  background-position: 600px 0;
	}
  }

  img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0;
	transition: opacity 0.3s ease;
	z-index: 1;
  }

  img.image-loaded {
	opacity: 1;
  }

  .active_overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
  }

  @keyframes shineEffect {
    0% {
      transform: translateX(-100%) skewX(-15deg);
      opacity: 1;
    }
    99% {
      transform: translateX(200%) skewX(-15deg);
      opacity: 1;
    }
    100% {
      transform: translateX(200%) skewX(-15deg);
      opacity: 0;
    }
  }

  .item_grid_album.highlight-recent .album_cover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(32, 196, 245, 0.3),
      transparent
    );
    z-index: 2;
    animation: shineEffect 1.5s ease-in-out forwards;
  }

  /* Remove the old highlight styles */
  .item_grid_album.highlight-recent {
    animation: none;
  }

  .item_grid_album.highlight-recent .album_cover {
    animation: none;
  }
  </style>
