import Hls from "hls.js";
import axios from "axios";
import { 
    cleanupHlsInstance, 
    createHlsConfig, 
    setupErrorHandling 
} from "./hlsErrorHandler";

const initialState = () => ({
    activeReq: null,
    currentTrack: null,
    currentTrackIdx: null,
    currentAlbum: null,
    currentFromPlay: '',

    sound: null,
    playing: false,
    playerStatus: false,
    shuffle: false,
    loop: false,
    muted: false,
    soundVolume: 60,
    player: {},
    volume: 0.5,
    duration: 0,
    alertNextAlbum: '',

    playedZone: [],
    trackList: [],
    playedOnlyFavTracks: false,

    trackOnload: false,
    progress: null,
    fullAlbum: '',
    fullAlbumLoading: false,
    loadingReleaseID: null,
    soundError: false,
    isOnline: window.navigator.onLine,
    seek: false,
    bufferProgress: 0,
    retryAttempts: 0,
    retryStatus: '',
    pendingAutoplay: false,
});

export default {
    state: initialState(),
    mutations: {
        SET_SOUND(state, track) {
              if (Hls.isSupported()) {
                  if (state.hlsInstance) {
                      // Clean up previous HLS instance properly
                      state.hlsInstance.destroy();
                  }
                    
                  state.trackOnload = false;
                  
                  // Get the existing audio element instead of creating a new one
                  // This is crucial for iOS to maintain playback permission
                  state.sound = document.getElementById('player');
                  
                  // Reset the audio element state without replacing it
                  if (state.sound) {
                      // Clear any existing sources and listeners before reusing
                      state.sound.pause();
                      state.sound.src = '';
                      state.sound.load();
                      
                      // Remove any previous media event listeners
                      const mediaEvents = ['seeking', 'seeked', 'ended', 'waiting', 'canplaythrough'];
                      mediaEvents.forEach(eventName => {
                          const listeners = state.sound[`_${eventName}Listeners`] || [];
                          listeners.forEach(listener => {
                              state.sound.removeEventListener(eventName, listener);
                          });
                          state.sound[`_${eventName}Listeners`] = [];
                      });
                      
                      // Add new seeking event listeners
                      const onSeeking = () => {
                          console.log(`🔍 Media seeking: ${state.sound.currentTime.toFixed(2)}s`);
                      };
                      
                      const onSeeked = () => {
                          console.log(`✓ Media seeked to: ${state.sound.currentTime.toFixed(2)}s`);
                      };
                      
                      // Store references to listeners for later removal
                      state.sound._seekingListeners = [onSeeking];
                      state.sound._seekedListeners = [onSeeked];
                      
                      // Add event listeners
                      state.sound.addEventListener('seeking', onSeeking);
                      state.sound.addEventListener('seeked', onSeeked);
                  }
                    
                  // Use the HLS configuration from the error handler module
                  const hlsConfig = createHlsConfig();
                  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                    
                  // Create a patched version of Hls.js that handles potential errors in its internal methods
                  let hlsInstance;
                  try {
                      hlsInstance = new Hls(hlsConfig);
                      
                      // Add a safety wrapper for key HLS methods that might fail with the "this.log is not a function" error
                      const originalAttachMedia = hlsInstance.attachMedia;
                      hlsInstance.attachMedia = function(media) {
                          try {
                              return originalAttachMedia.call(this, media);
                          } catch (error) {
                              console.log('Safely handled HLS attachMedia error:', error.message);
                              // Still attempt to complete the operation
                              try {
                                  media.src = state.currentTrack.link;
                                  media.load();
                              } catch (e) {
                                  // Last resort fallback
                              }
                          }
                      };
                      
                      state.hlsInstance = hlsInstance;
                  } catch (error) {
                      console.error("Error creating HLS instance:", error);
                      // Fallback to regular HTML5 Audio
                      if (state.sound) {
                          state.sound.src = track.link;
                          state.sound.load();
                      }
                  }
                  
                  // Store reference to current track for error handling
                  state.currentTrack = track;
                    
                  // Remove any previous 'ended' event listeners to prevent duplicates
                  const oldEndedListeners = state.sound._endedListeners || [];
                  oldEndedListeners.forEach(listener => {
                      state.sound.removeEventListener('ended', listener);
                  });
                  
                  // Create a new 'ended' event handler
                  const onEnded = () => {
                      console.log('\n=== Track End DEBUG ===');
                      console.log('Current state:', {
                          track: {
                              id: track?.id,
                              title: track?.title
                          },
                          album: {
                              id: state.currentAlbum?.id,
                              title: state.currentAlbum?.title,
                              trackCount: state.currentAlbum?.playList?.length || 0
                          },
                          page: this.getters['ALBUMS_CURRENT_PAGE'],
                          timestamp: new Date().toISOString()
                      });

                      if (this.getters.IS_TOKEN_ACTIVE === false) {
                          console.log('🔄 Token inactive, refreshing...');
                          let refreshToken = this.getters['AUTH_DATA'].refreshToken || localStorage.getItem('refreshToken');
                          this.dispatch('REFRESH_TOKEN', refreshToken);
                          return;
                      }
    
                      if (this.getters['CURRENT_ALBUM'].playList) {
                          const currentPlaylist = this.getters['CURRENT_ALBUM'].playList;
                          const lastIndexTrack = currentPlaylist.length - 1;
                          const isLastTrack = currentPlaylist[lastIndexTrack].song.id === track.id;
    
                          console.log('📊 Playlist status:', {
                              currentTrack: track.id,
                              lastTrack: currentPlaylist[lastIndexTrack].song.id,
                              isLastTrack,
                              totalTracks: currentPlaylist.length,
                              currentIndex: state.currentTrackIdx
                          });

                          if (isLastTrack) {
                              console.log('📀 Last track of album, moving to next album');
                              this.dispatch('SKIP_ALBUM', 'next');
                          } else if (!state.loop) {
                              console.log('▶️ Moving to next track within album');
                              this.dispatch('SKIP_TRACK', 'next');
                          } else {
                              console.log('🔁 Loop mode active, restarting track');
                          }
                      } else {
                          console.warn('⚠️ Current album has no playlist');
                          this.dispatch('SKIP_TRACK', 'next');
                      }
                  };
                  
                  // Store reference to the listener for later removal
                  state.sound._endedListeners = [onEnded];
                  
                  state.sound.addEventListener('ended', onEnded);
                    
                  try {
                      state.hlsInstance.loadSource(track.link);
                      state.hlsInstance.attachMedia(state.sound);
                  } catch (error) {
                      console.log('Safely handled HLS operation error:', error.message);
                      // Fallback to regular HTML5 Audio if HLS fails
                      if (state.sound) {
                          state.sound.src = track.link;
                          state.sound.load();
                      }
                  }
                  
                  // Add waiting and canplaythrough event handlers
                  const onWaiting = () => {
                      console.log(`⏳ Media waiting at: ${state.sound.currentTime.toFixed(2)}s`);
                  };
                  
                  const onCanPlayThrough = () => {
                      console.log(`✅ Media can play through`);
                  };
                  
                  // Store references to listeners for later removal
                  state.sound._waitingListeners = [onWaiting];
                  state.sound._canplaythroughListeners = [onCanPlayThrough];
                  
                  // Add event listeners
                  state.sound.addEventListener('waiting', onWaiting);
                  state.sound.addEventListener('canplaythrough', onCanPlayThrough);
                    
                  state.hlsInstance.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
                      console.log(
                          'manifest parsed, found ' + data.levels.length + ' quality level',
                          event, data, new Date()
                      );
                  
                      state.trackOnload = true;
                      
                      // Start playing after manifest is parsed if we're supposed to be playing
                      if (state.playing) {
                          try {
                              console.log("🎵 Starting playback after manifest parsed");
                              const playPromise = state.sound.play();
                              
                              if (playPromise !== undefined) {
                                  playPromise
                                      .then(() => {
                                          console.log("✅ Playback started successfully");
                                          // Clear any pending autoplay flag if playback succeeds
                                          state.pendingAutoplay = false;
                                      })
                                      .catch(error => {
                                          console.error("❌ Playback failed:", error);
                                          
                                          // Handle iOS-specific autoplay restrictions
                                          if (error.name === 'NotAllowedError') {
                                              console.warn("🔊 Autoplay prevented - waiting for user interaction");
                                              
                                              // Set a flag to try playing again when user interacts
                                              state.pendingAutoplay = true;
                                              
                                              // Set up a one-time click listener to enable playback
                                              if (isIOS) {
                                                  const enableAudio = () => {
                                                      const playAttempt = state.sound.play();
                                                      if (playAttempt) {
                                                          playAttempt.catch(e => console.warn("Play attempt failed:", e));
                                                      }
                                                      document.removeEventListener('click', enableAudio);
                                                      document.removeEventListener('touchstart', enableAudio);
                                                  };
                                                  document.addEventListener('click', enableAudio, { once: true });
                                                  document.addEventListener('touchstart', enableAudio, { once: true });
                                              }
                                          } else {
                                              // For other errors, try again after a short delay
                                              setTimeout(() => {
                                                  try {
                                                      state.sound.play()
                                                          .catch(e => console.error("Retry play failed:", e));
                                                  } catch (e) {
                                                      console.error("Error in play retry:", e);
                                                  }
                                              }, 300);
                                          }
                                      });
                              }
                          } catch (e) {
                              console.error("❌ Error starting playback:", e);
                          }
                      }
                  });
                  
                  // Set up error handling using the error handler module
                  setupErrorHandling(state.hlsInstance, state, this);
                  
              } else {
                  console.log("HLS is not supported. Trying alternative method.");

                  state.trackOnload = false;
                  
                  // For non-HLS fallback, still try to reuse the existing audio element if possible
                  if (state.sound) {
                      state.sound.pause();
                      state.sound.src = '';
                      state.sound.load();
                      
                      // Remove any previous media event listeners
                      const mediaEvents = ['seeking', 'seeked', 'ended', 'waiting', 'canplaythrough', 'error'];
                      mediaEvents.forEach(eventName => {
                          const listeners = state.sound[`_${eventName}Listeners`] || [];
                          listeners.forEach(listener => {
                              state.sound.removeEventListener(eventName, listener);
                          });
                          state.sound[`_${eventName}Listeners`] = [];
                      });
                  } else {
                      state.sound = new Audio();
                  }
                  
                  // Set the source after potentially reusing the element
                  state.sound.src = track.link;
                  state.sound.crossOrigin = 'anonymous';
                  
                  // Store reference to current track for error handling
                  state.currentTrack = track;
                  
                  // Add event listeners with proper cleanup
                  const canPlayHandler = () => {
                      state.trackOnload = true;
                      if (state.playing) {
                          const playPromise = state.sound.play();
                          if (playPromise) {
                              playPromise.catch(error => {
                                  console.error("Error starting fallback playback:", error);
                                  
                                  // Handle autoplay restrictions
                                  if (error.name === 'NotAllowedError') {
                                      console.warn("🔊 Autoplay prevented in fallback - waiting for user interaction");
                                      state.pendingAutoplay = true;
                                      
                                      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                                      if (isIOS) {
                                          const enableAudio = () => {
                                              const playAttempt = state.sound.play();
                                              if (playAttempt) {
                                                  playAttempt.catch(e => console.warn("iOS fallback play attempt failed:", e));
                                              }
                                              document.removeEventListener('click', enableAudio);
                                              document.removeEventListener('touchstart', enableAudio);
                                          };
                                          document.addEventListener('click', enableAudio, { once: true });
                                          document.addEventListener('touchstart', enableAudio, { once: true });
                                      }
                                  }
                              });
                          }
                      }
                  };
                  
                  const errorHandler = (error) => {
                      console.error("Error loading audio:", error);
                      state.soundError = true;
                  };
                  
                  // Create an ended handler consistent with the HLS one
                  const onEnded = () => {
                      console.log('\n=== Track End DEBUG (Fallback) ===');
                      console.log('Current state:', {
                          track: {
                              id: track?.id,
                              title: track?.title
                          },
                          album: {
                              id: state.currentAlbum?.id,
                              title: state.currentAlbum?.title
                          },
                          timestamp: new Date().toISOString()
                      });
                      
                      if (this.getters['CURRENT_ALBUM'].playList) {
                          const currentPlaylist = this.getters['CURRENT_ALBUM'].playList;
                          const lastIndexTrack = currentPlaylist.length - 1;
                          const isLastTrack = currentPlaylist[lastIndexTrack].song.id === track.id;
                          
                          if (isLastTrack) {
                              console.log('📀 Last track of album, moving to next album');
                              this.dispatch('SKIP_ALBUM', 'next');
                          } else if (!state.loop) {
                              console.log('▶️ Moving to next track within album');
                              this.dispatch('SKIP_TRACK', 'next');
                          } else {
                              console.log('🔁 Loop mode active, restarting track');
                          }
                      } else {
                          console.warn('⚠️ Current album has no playlist');
                          this.dispatch('SKIP_TRACK', 'next');
                      }
                  };
                  
                  // Add seeking event handlers
                  const onSeeking = () => {
                      console.log(`🔍 Media seeking (fallback): ${state.sound.currentTime.toFixed(2)}s`);
                  };
                  
                  const onSeeked = () => {
                      console.log(`✓ Media seeked to (fallback): ${state.sound.currentTime.toFixed(2)}s`);
                  };
                  
                  // Store references to event listeners
                  state.sound._canplaythroughListeners = [canPlayHandler];
                  state.sound._errorListeners = [errorHandler];
                  state.sound._endedListeners = [onEnded];
                  state.sound._seekingListeners = [onSeeking];
                  state.sound._seekedListeners = [onSeeked];
                  
                  // Add all event listeners
                  state.sound.addEventListener('canplaythrough', canPlayHandler);
                  state.sound.addEventListener('error', errorHandler);
                  state.sound.addEventListener('ended', onEnded);
                  state.sound.addEventListener('seeking', onSeeking);
                  state.sound.addEventListener('seeked', onSeeked);
              }
          },

        SET_PLAYED_ZONE: (state, data) => {
            state.playedZone = data;
        },
        SET_TRACK_LIST: (state, data) => {
            state.trackList = data;
        },
        SET_PLAYED_ONLY_FAV_TRACKS: (state, data) => {
            state.playedOnlyFavTracks = data;
        },
        SET_CURRENT_FROM_PLAY(state, data) {
            state.currentFromPlay = data;
        },
        SET_CURRENT_TRACK(state, data) {
            state.currentTrack = data;
        },
        SET_CURRENT_TRACK_INDEX(state, data) {
            state.currentTrackIdx = data;
        },
        SET_CURRENT_ALBUM(state, data) {
            state.currentAlbum = data;
        },
        SET_CURRENT_PLAYING(state, status = true) {
            state.playing = status;
        },
        SET_PLAY(state) {
            try {
                console.log("SET_PLAY mutation called");
                
                // Ensure the audio element exists before trying to play
                if (!state.sound) {
                    console.error("Audio element not initialized");
                    return;
                }
                
                // For iOS, we need to handle the play promise carefully
                const playPromise = state.sound.play();
                
                if (playPromise !== undefined) {
                    playPromise
                        .then(() => {
                            console.log("✅ Play command successful");
                            // Clear any pending autoplay flag if playback succeeds
                            state.pendingAutoplay = false;
                        })
                        .catch(error => {
                            console.error("❌ Play command failed:", error);
                            
                            // This could be an autoplay restriction, try again after a user interaction
                            if (error.name === 'NotAllowedError') {
                                console.warn("🔊 Autoplay prevented - waiting for user interaction");
                                state.pendingAutoplay = true;
                                
                                // Set up listeners to enable audio on user interaction
                                const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                                if (isIOS) {
                                    const enableAudio = () => {
                                        const playAttempt = state.sound.play();
                                        if (playAttempt) {
                                            playAttempt.catch(e => console.warn("iOS play attempt failed:", e));
                                        }
                                        document.removeEventListener('click', enableAudio);
                                        document.removeEventListener('touchstart', enableAudio);
                                    };
                                    document.addEventListener('click', enableAudio, { once: true });
                                    document.addEventListener('touchstart', enableAudio, { once: true });
                                }
                            }
                        });
                }
            } catch (e) {
                console.error("❌ Error in SET_PLAY:", e);
            }
        },
        SET_STOP(state) {
            state.sound.pause();
        },
        SET_PAUSE(state) {
            state.sound.pause();
        },
        SET_PLAYER_STATUS(state, status) {
            state.playerStatus = status;
        },
        SET_SHUFFLE(state, data) {
            state.shuffle = data;
        },
        SET_LOOP(state) {
            state.loop = !state.loop;
            state.sound.loop(state.loop);
        },
        SET_MUTED(state) {
            state.muted = !state.muted;
            state.sound.mute(state.muted);
        },
        SET_SOUND_VOLUME(state, data) {
            state.player.volume(data);
        },
        SET_PROGRESS: (state, progress) => {
            state.progress = progress;
        },
        SET_FULL_ALBUM_TO_STATE: (state, data) => {
            state.fullAlbum = data;
        },
        SET_FULL_ALBUM_LOADING: (state, data) => {
            state.fullAlbumLoading = data;
        },
        SET_LOADING_RELEASE: (state, id) => {
            state.loadingReleaseID = id;
        },
        SET_PLAYER(state) {
            // state.player = Howler;
        },
        SET_IS_LISTENED_ALBUM(state, data) {
            data.isListened = true;
        },
        SET_ALERT_NEXT_ALBUM: (state, data) => {
            state.alertNextAlbum = data;
        },
        SET_BUFFER_PROGRESS: (state, data) => {
            state.bufferProgress = data;
        },
        // TODO: !!!
        SET_SEEK: (state, data) => {
            state.seek = data;
        },
    },
    actions: {
        SET_PLAY({commit, state}, params = {}) {
            const {
                fromPlay = 'album_default',
                track = state.currentTrack,
                index = state.currentTrackIdx,
                album = state.currentAlbum,
                isDiscoveryStatus = false
            } = params;

            // fromPlay = 'album_default'

            // console.log('SET_PLAY - fromPlay', fromPlay);
            // console.log('state.isOnline', state.isOnline);
            // commit('SET_PROGRESS', null);
            if (state.isOnline === false) {
                console.log('offline mode');
            }
            // set album logic
            // if (isDiscoveryStatus) {
            commit('SET_IS_LISTENED_ALBUM', album);
            // }

            commit('SET_CURRENT_ALBUM', album);
            if (!state.playerStatus) {
                commit('SET_PLAYER_STATUS', true);
            }

            // set track logic
            commit('SET_CURRENT_TRACK_INDEX', index);

            if (state.currentTrack && state.currentTrack.id !== track.id) {
                commit('SET_STOP');
                commit('SET_CURRENT_TRACK', track);
                commit('SET_SOUND', track, index, album);
            } else {
                if (!state.currentTrack && !state.sound) {
                    commit('SET_CURRENT_TRACK', track);
                    commit('SET_SOUND', track, index, album);
                }
            }

            // TODO: // check SET_CURRENT_FROM_PLAY
            commit('SET_CURRENT_FROM_PLAY', fromPlay || 'album_default');

            if (fromPlay !== 'track_default' && state.shuffle === false) {
                if (this.getters['AUTH_DATA'].accessToken) {
                    this.dispatch('INCREASE_PLAYED_MUSIC_RELEASE', {'musicReleaseID': album.id});
                }
            }

            commit('SET_PLAY');
            commit('SET_CURRENT_PLAYING');
            commit('SET_PLAYER');
        },

        SET_PLAYED_ONLY_FAV_TRACKS({commit}, data) {
            commit('SET_PLAYED_ONLY_FAV_TRACKS', data);
        },
        SET_TRACK_LIST({commit}, data) {
            commit('SET_TRACK_LIST', data);
        },
        SET_PAUSE({commit}) {
            commit('SET_CURRENT_PLAYING', false);
            commit('SET_PAUSE');
        },

        // STOP
        STOP_PLAY({commit}) {
            commit('SET_STOP');
            commit('SET_PLAYER_STATUS', false);

            commit('SET_CURRENT_TRACK', null);
            commit('SET_CURRENT_TRACK_INDEX', null);
            commit('SET_CURRENT_PLAYING', false);
            commit('SET_CURRENT_ALBUM', null);
        },
        SET_PLAYED_ZONE({commit}, data) {
            commit('SET_PLAYED_ZONE', data);
        },

        TOGGLE_SHUFFLE({commit}) {
            commit('SET_SHUFFLE');
        },
        TOGGLE_LOOP({commit}) {
            commit('SET_LOOP')
        },
        TOGGLE_MUTE({commit}) {
            commit('SET_MUTED')
        },
        UPDATE_PROGRESS({commit}, data) {
            commit('SET_PROGRESS', data);
        },

        SKIP_TRACK({commit, state}, direction) {
            let index = 0;
            let album;
            let track;

            // Reset error state when skipping tracks
            if (state.soundError) {
                state.soundError = false;
            }

            // Clean up HLS instance before skipping using the error handler module
            cleanupHlsInstance(state);

            if (state.shuffle === true) {
                this.dispatch('SKIP_ALBUM', 'next');
                return;
            }

            if (direction === 'prev') {
                index = state.currentTrackIdx - 1;

                if (state.currentAlbum.playList) {

                    if (state.playedOnlyFavTracks === true) {
                        index = this.getters.favIndexFinder(direction);
                        if (!index && this.getters['PLAYED_ZONE'].length > 0) {
                            this.dispatch('SKIP_ALBUM', 'prev');
                            return;
                        }
                    }

                    if (index < 0) {
                        this.dispatch('SKIP_ALBUM', 'prev');
                        return;
                    }
                }
            }
            if (direction === 'next') {

                // find index next track
                index = state.currentTrackIdx + 1;

                // album playList
                if (state.currentAlbum.playList) {

                    if (state.playedOnlyFavTracks === true) {
                        index = this.getters.favIndexFinder(direction);
                        if (!index && this.getters['PLAYED_ZONE'].length > 0) {
                            this.dispatch('SKIP_ALBUM', 'next');
                            return;
                        }
                    }

                    if (index >= state.currentAlbum.playList.length) {
                        this.dispatch('SKIP_ALBUM', 'next');
                        return;
                    }

                }
            }


            // album playList
            if (state.currentAlbum.playList) {
                let playlist = state.currentAlbum.playList.filter(track => !track.song.deletedAt && track.song.link);

                if (index < 0) {
                    index = 0;
                }
                if (index >= playlist.length) {
                    index = 0;
                }

                track = playlist[index].song;

                album = state.currentAlbum;

            } else if (state.trackList.length) {
                if (index >= state.trackList.length) {
                    index = 0;
                }
                if (index < 0) {
                    index = state.trackList.length - 1;
                }


                // library
                if (state.trackList[index].song) {
                    track = state.trackList[index].song;
                } else {
                    // default
                    track = state.trackList[index];
                }

                // default
                if (track.playLists) {
                    album = track.playLists[0].musicRelease;
                } else {
                    // library
                    album = track.musicRelease;
                }
            } else {
                console.log('need release-playlist || trackList');
            }

            let skipTrack = {track: track, index, album};

            this.dispatch('SET_PLAY', skipTrack);
        },

        SKIP_ALBUM({commit, state}, direction) {
            console.log('\n=== SKIP_ALBUM DEBUG ===');
            console.log('Direction:', direction);
            console.log('Current album:', {
                id: state.currentAlbum?.id,
                title: state.currentAlbum?.title,
                trackCount: state.currentAlbum?.playList?.length || 0
            });

            // Reset error state when skipping albums
            if (state.soundError) {
                state.soundError = false;
            }

            // Clean up HLS instance before skipping using the error handler module
            cleanupHlsInstance(state);

            const albums = this.getters['PLAYED_ZONE'].filter(album => 
                (album.deletedAt && album.havePurchased === true) || album.status !== 'draft'
            );

            if (albums.length) {
                const currAlbumIdx = albums.findIndex((({id}) => id === state.currentAlbum?.id));
                const currentPage = this.getters['ALBUMS_CURRENT_PAGE'];
                const totalPages = Math.ceil(this.getters['COUNT_EXPLORE_GRID_ALBUMS'] / 30);

                console.log('📊 Navigation Status:', {
                    currentAlbumNumber: currAlbumIdx + 1,
                    totalAlbumsOnPage: albums.length,
                    currentPage,
                    totalPages,
                    hasNextPage: currentPage < totalPages,
                    isLastAlbum: currAlbumIdx === albums.length - 1
                });

                let skipAlbum;
                let trackIndex = 0;

                if (state.shuffle === true) {
                    console.log('🔀 Shuffle mode active');
                    skipAlbum = albums[Math.round(Math.random() * (albums.length - 1))];
                }
                else if (direction === 'prev') {
                    if (currAlbumIdx <= 0) {
                        if (currentPage > 1) {
                            console.log('📄 Loading previous page:', currentPage - 1);
                            
                            const params = {
                                sort: 'releasedAt',
                                order: 'desc',
                                status: 'published',
                                page: currentPage - 1
                            };
                            
                            if (this.getters['DISCOVERY'] === true) {
                                params.playedMusicReleases = false;
                            }

                            // Get previous page albums first
                            return this.dispatch('GET_EXPLORE_GRID_ALBUMS', params)
                                .then(() => {
                                    const newAlbums = this.getters['EXPLORE_GRID_ALBUMS'];
                                    if (!newAlbums || newAlbums.length === 0) {
                                        throw new Error('No albums found on previous page');
                                    }

                                    console.log('📥 Previous page albums:', newAlbums.map(a => ({
                                        id: a.id,
                                        title: a.title
                                    })));

                                    // Update state
                                    commit('SET_ALBUMS_CURRENT_PAGE', currentPage - 1);
                                    commit('SET_PLAYED_ZONE', newAlbums);

                                    // Get last album of previous page
                                    const lastAlbum = newAlbums[newAlbums.length - 1];
                                    return this.dispatch('GET_FULL_ALBUM', {
                                        id: lastAlbum.id,
                                        title: lastAlbum.title
                                    });
                                })
                                .then(() => {
                                    const album = this.getters['FULL_ALBUM'];
                                    if (!album || !album.playList || !album.playList.length) {
                                        throw new Error('Album has no playlist');
                                    }

                                    console.log('▶️ Starting playback:', {
                                        album: {
                                            id: album.id,
                                            title: album.title,
                                            trackCount: album.playList.length
                                        },
                                        page: currentPage - 1
                                    });

                                    // Ensure clean state before playing
                                    cleanupHlsInstance(state);

                                    // Center the album in the viewport
                                    this._vm.$nextTick(() => {
                                        console.log('📜 Centering album in viewport');
                                        const albumElement = document.querySelector(`[data-album-id="${album.id}"]`);
                                        if (albumElement) {
                                            const viewportHeight = window.innerHeight;
                                            const albumRect = albumElement.getBoundingClientRect();
                                            const scrollPosition = window.scrollY + albumRect.top - (viewportHeight - albumRect.height) / 2;
                                            
                                            window.scrollTo({
                                                top: scrollPosition,
                                                behavior: 'smooth'
                                            });
                                            console.log('📜 Album centered:', { albumId: album.id, scrollPosition });
                                        } else {
                                            console.log('📜 Album element not found:', album.id);
                                        }
                                    });

                                    commit('SET_IS_LISTENED_ALBUM', album);
                                    commit('SET_CURRENT_ALBUM', album);

                                    // Start with last track of the album
                                    const lastTrackIndex = album.playList.length - 1;
                                    return this.dispatch('SET_PLAY', {
                                        fromPlay: 'album_default',
                                        track: album.playList[lastTrackIndex].song,
                                        index: lastTrackIndex,
                                        album: album,
                                        isDiscoveryStatus: this.getters['DISCOVERY']
                                    });
                                })
                                .catch(error => {
                                    console.error('❌ Failed to load album:', {
                                        error: error.message,
                                        albumId: skipAlbum.id,
                                        page: currentPage,
                                        timestamp: new Date().toISOString()
                                    });
                                    
                                    // Clean up on error
                                    cleanupHlsInstance(state);
                                    
                                    // Reset error states
                                    state.soundError = false;
                                    state.retryAttempts = 0;
                                    state.retryStatus = '';
                                    
                                    // Try to recover by moving to the next album
                                    if (direction === 'next' && currAlbumIdx + 2 < albums.length) {
                                        console.log('Attempting to recover by skipping to next album');
                                        skipAlbum = albums[currAlbumIdx + 2];
                                        return this.dispatch('SKIP_ALBUM', 'next');
                                    }
                                });
                        }
                        console.log('⏭️ On first page, wrapping to last album');
                        skipAlbum = albums[albums.length - 1];
                    } else {
                        console.log('⏮️ Moving to previous album');
                        skipAlbum = albums[currAlbumIdx - 1];
                    }
                }
                else if (direction === 'next') {
                    if (currAlbumIdx + 1 === albums.length) {
                        if (currentPage < totalPages) {
                            console.log('📄 Loading next page:', currentPage + 1);
                            
                            const params = {
                                sort: 'releasedAt',
                                order: 'desc',
                                status: 'published',
                                page: currentPage + 1
                            };
                            
                            if (this.getters['DISCOVERY'] === true) {
                                params.playedMusicReleases = false;
                            }

                            // Get new page albums first
                            return this.dispatch('GET_EXPLORE_GRID_ALBUMS', params)
                                .then(() => {
                                    const newAlbums = this.getters['EXPLORE_GRID_ALBUMS'];
                                    if (!newAlbums || newAlbums.length === 0) {
                                        throw new Error('No albums found on next page');
                                    }

                                    console.log('📥 New page albums:', newAlbums.map(a => ({
                                        id: a.id,
                                        title: a.title
                                    })));

                                    // Update state
                                    commit('SET_ALBUMS_CURRENT_PAGE', currentPage + 1);
                                    commit('SET_PLAYED_ZONE', newAlbums);

                                    // Get first album of new page
                                    const firstAlbum = newAlbums[0];
                                    return this.dispatch('GET_FULL_ALBUM', {
                                        id: firstAlbum.id,
                                        title: firstAlbum.title
                                    });
                                })
                                .then(() => {
                                    const album = this.getters['FULL_ALBUM'];
                                    if (!album || !album.playList || !album.playList.length) {
                                        throw new Error('Album has no playlist');
                                    }

                                    console.log('▶️ Starting playback:', {
                                        album: {
                                            id: album.id,
                                            title: album.title,
                                            trackCount: album.playList.length
                                        },
                                        page: currentPage + 1
                                    });

                                    // Ensure clean state before playing
                                    cleanupHlsInstance(state);

                                    // Center the album in the viewport
                                    this._vm.$nextTick(() => {
                                        console.log('📜 Centering album in viewport');
                                        const albumElement = document.querySelector(`[data-album-id="${album.id}"]`);
                                        if (albumElement) {
                                            const viewportHeight = window.innerHeight;
                                            const albumRect = albumElement.getBoundingClientRect();
                                            const scrollPosition = window.scrollY + albumRect.top - (viewportHeight - albumRect.height) / 2;
                                            
                                            window.scrollTo({
                                                top: scrollPosition,
                                                behavior: 'smooth'
                                            });
                                            console.log('📜 Album centered:', { albumId: album.id, scrollPosition });
                                        } else {
                                            console.log('📜 Album element not found:', album.id);
                                        }
                                    });

                                    commit('SET_IS_LISTENED_ALBUM', album);
                                    commit('SET_CURRENT_ALBUM', album);

                                    return this.dispatch('SET_PLAY', {
                                        fromPlay: 'album_default',
                                        track: album.playList[0].song,
                                        index: 0,
                                        album: album,
                                        isDiscoveryStatus: this.getters['DISCOVERY']
                                    });
                                })
                                .catch(error => {
                                    console.error('❌ Failed to load album:', {
                                        error: error.message,
                                        albumId: skipAlbum.id,
                                        page: currentPage,
                                        timestamp: new Date().toISOString()
                                    });
                                    
                                    // Clean up on error
                                    cleanupHlsInstance(state);
                                    
                                    // Reset error states
                                    state.soundError = false;
                                    state.retryAttempts = 0;
                                    state.retryStatus = '';
                                    
                                    // Try to recover by moving to the next album
                                    if (direction === 'next' && currAlbumIdx + 2 < albums.length) {
                                        console.log('Attempting to recover by skipping to next album');
                                        skipAlbum = albums[currAlbumIdx + 2];
                                        return this.dispatch('SKIP_ALBUM', 'next');
                                    }
                                });
                        }
                        console.log('⏭️ On last page, wrapping to first album');
                        skipAlbum = albums[0];
                    } else {
                        console.log('⏭️ Moving to next album');
                        skipAlbum = albums[currAlbumIdx + 1];
                    }
                }

                console.log('🎵 Target album:', {
                    id: skipAlbum?.id,
                    title: skipAlbum?.title,
                    position: albums.findIndex(a => a.id === skipAlbum?.id) + 1
                });

                let params = { id: skipAlbum.id, title: skipAlbum.title };
                return this.dispatch('GET_FULL_ALBUM', params).then(() => {
                    commit('SET_IS_LISTENED_ALBUM', skipAlbum);
                    console.log('✅ Album marked as listened:', skipAlbum.id);

                    if (this.getters['FULL_ALBUM'].playList && this.getters['FULL_ALBUM'].playList.length) {
                        if (state.shuffle === true) {
                            trackIndex = Math.round(Math.random() * (this.getters['FULL_ALBUM'].playList.length - 1));
                            console.log('🔀 Selected random track:', trackIndex + 1);
                        }
                        if (state.playedOnlyFavTracks === true) {
                            let favPlaylist = [];

                            for (let i = 0; i < this.getters['FULL_ALBUM'].playList.length; i++) {
                                if (this.getters['FULL_ALBUM'].playList[i].song.isFavorite) {
                                    favPlaylist.push(i);
                                }
                            }
                            if (favPlaylist.length) {
                                trackIndex = favPlaylist[0];
                                console.log('⭐ Selected first favorite track:', trackIndex + 1);
                            } else {
                                trackIndex = 0;
                                console.log('⚠️ No favorite tracks, starting from beginning');
                            }
                        }

                        console.log('▶️ Starting playback:', {
                            album: {
                                id: this.getters['FULL_ALBUM'].id,
                                title: this.getters['FULL_ALBUM'].title,
                                trackCount: this.getters['FULL_ALBUM'].playList.length
                            },
                            track: {
                                id: this.getters['FULL_ALBUM'].playList[trackIndex].song.id,
                                title: this.getters['FULL_ALBUM'].playList[trackIndex].song.title,
                                position: trackIndex + 1
                            },
                            page: currentPage,
                            timestamp: new Date().toISOString()
                        });

                        const data = {
                            fromPlay: 'album_default',
                            track: this.getters['FULL_ALBUM'].playList[trackIndex].song,
                            index: trackIndex,
                            album: this.getters['FULL_ALBUM'],
                            isDiscoveryStatus: this.getters['DISCOVERY']
                        }
                        return this.dispatch('SET_PLAY', data);
                    } else {
                        console.warn('⚠️ Album has no playlist or is empty');
                    }
                }).catch(error => {
                    console.error('❌ Failed to load album:', {
                        error: error.message,
                        albumId: skipAlbum.id,
                        page: currentPage,
                        timestamp: new Date().toISOString()
                    });
                });
            } else {
                console.warn('⚠️ No albums in played zone');
            }
        },

        SET_SOUND_VOLUME({commit, state}, data) {
            // TODO: NOT USES
            // TODO: check
            commit('SET_SOUND_VOLUME', data);
            if (+data < 0.2) {
                commit('SET_MUTED');
            }
            if (+data > 0.1 && state.sound._muted) {
                commit('SET_MUTED', false);
            }
        },

        ADD_SONG_STATISTIC({commit}, params) {
            // Available values : downloaded, purchased, listened_to
            return axios.post(`add_song_statistic/${params.value}`, {'song_id': params.id})
                .then(response => {

                })
                .catch(err => {
                    console.log(`ADD_SONG_STATISTIC, ${err}`);
                })
        },


        // main album
        GET_FULL_ALBUM({commit, state}, params) {
            if (this.getters['CURRENT_PAGE'].name !== 'albumDetails') {
                commit('SET_FULL_ALBUM_LOADING', true);
                commit('SET_LOADING_RELEASE', params.id);
            }
            //  withDeletedSong: true
            let route = `music_releases/${params.id || params.name}`;

            return axios.get(`${route}`, ) // {params: params.title}
                .then(album => {
                    commit('SET_FULL_ALBUM_TO_STATE', album.data);
                })
                .catch(error => {
                    console.error(`GET_FULL_ALBUM ${error}`);
                })
                .finally(() => {
                    commit('SET_FULL_ALBUM_LOADING', false);
                    commit('SET_LOADING_RELEASE', null);
                })
        }
    },

    getters: {
        CURRENT_FROM_PLAY: (state) => state.currentFromPlay,
        CURRENT_TRACK: (state) => state.currentTrack,
        CURRENT_ALBUM: (state) => state.currentAlbum,
        PLAYING: (state) => state.playing,
        PLAYER_STATUS: (state) => state.playerStatus,
        SOUND: (state) => state.sound,
        SHUFFLE: (state) => state.shuffle,
        CURRENT_TRACK_IDX: (state) => state.currentTrackIdx,
        LOOP: (state) => state.loop,
        MUTED: (state) => state.muted,
        DURATION: (state) => state.duration,
        ALERT_NEXT_ALBUM: (state) => state.alertNextAlbum,
        PLAYED_ZONE: (state) => state.playedZone,
        TRACK_LIST: state => state.trackList,
        PLAYED_ONLY_FAV_TRACKS: (state) => state.playedOnlyFavTracks,
        TRACK_ONLOAD: state => state.trackOnload,
        RETRY_STATUS: state => state.retryStatus,

        favIndexFinder: (state) => (direction) => {
            let currentPlayingIndex = -1;
            let favPlaylist = [];

            for (let i = 0; i < state.currentAlbum.playList.length; i++) {
                if (state.currentAlbum.playList[i].song.isFavorite) {
                    favPlaylist.push(i);
                }
                if (state.currentTrack.id === state.currentAlbum.playList[i].song.id) {
                    currentPlayingIndex = i;
                }
            }
            const favIndex = favPlaylist.findIndex(x => x === currentPlayingIndex);

            let index = 0;
            if (direction === 'next') {
                index = favIndex + 1;
                if (index >= favPlaylist.length) {
                    // index = 0;
                    index = -1;
                }
            } else {
                index = favIndex - 1;
                if (index < 0) {
                    // index = favPlaylist.length - 1;
                    index = -1;
                }
            }

            return favPlaylist[index];
        },

        PROGRESS: (state) => state.progress,
        FULL_ALBUM: state => state.fullAlbum,
        FULL_ALBUM_LOADING: state => state.fullAlbumLoading,
        LOADING_RELEASE_ID: state => state.loadingReleaseID,
        SOUND_ERROR: state => state.soundError,
        SEEK: state => state.seek,
        BUFFER_PROGRESS: state => state.bufferProgress,
        // onlyValidTracks: (state) => (direction) => {
        //     let currentPlayingIndex = -1;
        //     let playlist = [];
        //
        //     for (let i = 0; i < state.currentAlbum.playList.length; i++) {
        //         const currentTrack = state.currentAlbum.playList[i].song;
        //         const currentRelease = state.currentAlbum;
        //         if (!currentTrack.deletedAt && (currentRelease.status === 'published' || currentRelease.status.value === 'published')) {
        //             playlist.push(i);
        //             console.log('push playlist', i);
        //         }
        //         if (state.currentTrack.id === state.currentAlbum.playList[i].song.id) {
        //             currentPlayingIndex = i;
        //         }
        //     }
        //     const setIndex = playlist.findIndex(x => x === currentPlayingIndex);
        //
        //     let index = 0;
        //     if (direction === 'next') {
        //         index = setIndex + 1;
        //         if (index >= playlist.length) {
        //             // index = 0;
        //             index = -1;
        //         }
        //     } else {
        //         index = setIndex - 1;
        //         if (index < 0) {
        //             // index = favPlaylist.length - 1;
        //             index = -1;
        //         }
        //     }
        //
        //     return playlist[index];
        // },

    },
}
