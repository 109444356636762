export const BETA_FEATURES = {
  UPLOAD_MUSIC: {
    storageKey: 'uploadVerified',
    passphrase: 'uploads2000!',
    title: 'Access Required',
    description: 'Upload functionality is currently accessible to beta testers only.',
    contactEmail: 'admin@psychurch.org'
  },
  ADD_EVENT: {
    storageKey: 'eventVerified',
    passphrase: 'events2001!',
    title: 'Access Required',
    description: 'Event creation functionality is currently accessible to beta testers only.',
    contactEmail: 'admin@psychurch.org'
  }
  // Add more beta features here as needed
  // Example:
  // FEATURE_NAME: {
  //   storageKey: 'featureVerified',
  //   passphrase: 'featurepassphrase',
  //   title: 'Feature Title',
  //   description: 'Feature description',
  //   contactEmail: 'contact@example.com'
  // }
} 